import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  ColumnOrderState,
  SortingState,
  OnChangeFn,
  RowSelectionState,
  Row,
  Cell,
  Table,
  Header,
  InitialTableState,
} from "@tanstack/react-table";
import _isFunction from "lodash/isFunction";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

import { useColumnsWithRowSelection } from "./use-columns-with-row-selection";
import { useListsContext } from "@/modules/pipeline/lists/context";

export interface DataTablePropsI<T> {
  listId?: string;

  data: T[];

  isSortable?: boolean;
  isSelectable?: boolean;

  initialState?: InitialTableState;

  onSort?: (sortingState: SortingState) => void;
  onSelect?: (selectedRows: number[]) => void;
  onClickRow?: (e: React.MouseEvent, row: Row<T>) => void;

  tableWrapperClassName?: string;
  tableClassName?: string;
  headerClassName?: string;
  headerCellClassName?: ((header: Header<T, unknown>) => string) | string;
  bodyClassName?: string;
  rowClassName?: ((row: Row<T>) => string) | string;
  cellClassName?: ((cell: Cell<T, keyof T>) => string) | string;
  getRowId?: (row: T) => string;

  renderSubElement?: (row: Row<T>) => React.ReactNode;
}

const DataTable = <T,>(
  {
    listId,
    data,

    isSortable = false,
    isSelectable = false,

    initialState,

    onSort,
    onSelect,
    onClickRow,

    getRowId,

    tableWrapperClassName,
    tableClassName,
    headerCellClassName,
    headerClassName,
    bodyClassName,
    rowClassName,
    cellClassName,

    renderSubElement,
  }: DataTablePropsI<T>,
  tableApiRef?: ForwardedRef<Table<T>>
) => {
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>(
    initialState?.sorting || []
  );

  const handleSortChange = useCallback(
    (sortingState: SortingState) => {
      setSorting(sortingState);

      // TODO Refactor that to avoid using setTimeout
      setTimeout(() => {
        if (onSort) {
          onSort(table.getState().sorting);
        }
      });
    },
    [onSort]
  );

  const handleSelect = useCallback(
    (rowSelection: RowSelectionState) => {
      setRowSelection(rowSelection);

      // TODO Refactor that to avoid using setTimeout
      setTimeout(() => {
        if (onSelect) {
          const selectionState = table.getState().rowSelection;

          onSelect(
            Object.keys(selectionState)
              .filter((key) => selectionState[key])
              .map((rowIndex) => Number(rowIndex))
          );
        }
      });
    },
    [onSelect]
  );

  const { columnVisibility, setColumnVisibility } = useListsContext();

  const { columnsWithRowSelection } = useColumnsWithRowSelection({
    listId,
    data,
    isSelectable,
  });

  const table = useReactTable<T>({
    columns: columnsWithRowSelection as ColumnDef<T>[],
    data,
    state: {
      columnOrder,
      columnVisibility,
      rowSelection,
      sorting,
    },
    initialState,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,

    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: handleSelect as OnChangeFn<RowSelectionState>,

    getCoreRowModel: getCoreRowModel(),

    getRowId,

    enableSorting: isSortable,
    onSortingChange: handleSortChange as OnChangeFn<SortingState>,
    manualSorting: true,
    sortDescFirst: true,
  });

  useEffect(() => {
    if (table && tableApiRef) {
      (tableApiRef as MutableRefObject<Table<T>>).current = table;
    }
  }, [table]);

  return (
    <div className={clsxMerge("brand-typography-body3", tableWrapperClassName)}>
      <table className={clsxMerge("w-full table-auto", tableClassName)}>
        <thead className={headerClassName}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const { tooltip } = header.column.columnDef.meta || {};

                const headerJSX = (
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                );

                return (
                  <th
                    key={header.id}
                    className={clsxMerge(
                      "whitespace-nowrap text-start font-normal",
                      {
                        "cursor-ns-resize": header.column.getCanSort(),
                        "px-2 py-4": header.column.id !== "select-column",
                      },
                      _isFunction(headerCellClassName)
                        ? headerCellClassName(header)
                        : headerCellClassName
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={clsxMerge(
                          "flex items-center justify-between",
                          "text-black/40 hover:text-black"
                        )}
                      >
                        {tooltip ? (
                          <Tooltip showDelay={50}>
                            <TooltipTrigger>{headerJSX}</TooltipTrigger>
                            <TooltipContent className="max-w-[185px] px-6 text-center">
                              {tooltip}
                            </TooltipContent>
                          </Tooltip>
                        ) : (
                          headerJSX
                        )}

                        {{
                          asc: <ChevronUpIcon className="h-4 w-4" />,
                          desc: <ChevronDownIcon className="h-4 w-4" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody className={clsxMerge("divide-y divide-black/10", bodyClassName)}>
          {Object.values(table.getRowModel().rowsById).map((row) => (
            <Fragment key={row.id}>
              <tr
                key={row.id}
                className={clsxMerge(
                  "group relative",
                  {
                    "cursor-pointer hover:bg-black/4": !!onClickRow,
                  },
                  _isFunction(rowClassName) ? rowClassName(row) : rowClassName
                )}
                onMouseDown={onClickRow ? (e) => onClickRow(e, row) : undefined}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={clsxMerge(
                      "max-w-[200px] truncate whitespace-nowrap text-start",
                      "[&>*]:min-h-[24px]",
                      {
                        "px-2 py-4": cell.column.id !== "select-column",
                      },
                      _isFunction(cellClassName)
                        ? cellClassName(cell as unknown as Cell<T, keyof T>)
                        : cellClassName
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}

                {renderSubElement && renderSubElement(row)}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default forwardRef(DataTable) as <T>(
  props: DataTablePropsI<T> & { ref?: React.ForwardedRef<Table<T>> }
) => ReturnType<typeof DataTable>;
