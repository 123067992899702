import { FC, useCallback, useEffect, useState } from "react";

import { clsxMerge } from "shared/lib/helpers";
import {
  ControllableComponentPropsI,
  PropsWithClassNameI,
} from "shared/lib/interfaces/ui";
import { LIST_SUBSECTIONS_MAP } from "shared/lib/constants/lists";
import { ListSubsectionI } from "shared/lib/interfaces/lists";

import { ListSubsectionButton } from "./subsection-button";
import {
  COLD_LEADS_BUTTON_ID,
  COLD_LIST_TOOLTIP,
  NURTURE_LEADS_BUTTON_ID,
  NURTURE_LIST_TOOLTIP,
} from "./constants";

interface ListTypeSwitchPropsI
  extends ControllableComponentPropsI<ListSubsectionI>,
    PropsWithClassNameI {
  listId?: string;
  nurtureLeadsTotal?: number;
  nurtureLeadsAvailable?: number;
  coldLeadsTotal?: number;
  coldLeadsAvailable?: number;
  isDisabled?: boolean;
  isDefaultList?: boolean;

  /**
   * This flag allows bypassing default visibility rules (like hiding for default
   * lists). This is useful for showing the switch is some special scenarios, like
   * in a lists tour.
   */
  isDemoMode?: boolean;
}

// Switcher between list types (nurture or cold call)
export const ListSubsectionSwitch: FC<ListTypeSwitchPropsI> = ({
  className,
  nurtureLeadsTotal,
  nurtureLeadsAvailable,
  coldLeadsTotal,
  coldLeadsAvailable,
  value,
  onChange,
  isDefaultList,
  isDisabled = false,
  isDemoMode,
}) => {
  const [selected, setSelected] = useState<ListSubsectionI>(
    value || LIST_SUBSECTIONS_MAP.NURTURE
  );

  const handleChangeListSubsection = useCallback(
    (listSubsection: ListSubsectionI) => {
      if (listSubsection === selected || isDemoMode) {
        return;
      }

      setSelected(listSubsection);
      onChange(listSubsection);
    },
    [selected, onChange, isDemoMode]
  );

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  if (isDefaultList && !isDemoMode) {
    return null;
  }

  return (
    <div className={clsxMerge("relative flex items-center gap-3", className)}>
      <ListSubsectionButton
        id={NURTURE_LEADS_BUTTON_ID}
        isDisabled={isDisabled}
        tooltip={NURTURE_LIST_TOOLTIP}
        subsection={LIST_SUBSECTIONS_MAP.NURTURE}
        numerator={nurtureLeadsAvailable}
        denominator={nurtureLeadsTotal}
        value={selected as ListSubsectionI}
        onChange={handleChangeListSubsection}
      />

      <ListSubsectionButton
        id={COLD_LEADS_BUTTON_ID}
        isDisabled={isDisabled}
        tooltip={COLD_LIST_TOOLTIP}
        subsection={LIST_SUBSECTIONS_MAP.COLD_CALL}
        numerator={coldLeadsAvailable}
        denominator={coldLeadsTotal}
        value={selected}
        onChange={handleChangeListSubsection}
      />
    </div>
  );
};
