/**
 * 3rd Party Libs
 */
import {
  EventHandler,
  FC,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import wait from "wait";

import { InitialTableState, Row, Table } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import _debounce from "lodash/debounce";

import { Portal } from "react-portal";

/**
 * API
 */
import {
  SearchContactsApiI,
  useFetchListContacts,
} from "@/modules/pipeline/lists/list/queries/queries";

import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { GetListResponseI } from "@/api/routes/list";

import { queryClient } from "@/api/query-client";

/**
 * Interface
 */
import { AccountReloadCompleteDataI } from "@/interfaces/custom-events";
import { LeadListsWorkspaceTablePropsI } from "./interfaces";

/**
 * Constants
 */
import { WIDGETS, widgets } from "@/components/shared/widgets";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { LIST_TABLE_COLUMNS } from "@/modules/pipeline/lists/list/constants";
import {
  LIST_ACTIONS_SLOT_ID,
  LIST_TABLE_HEADER_CELL_CLASSNAME,
} from "@/modules/pipeline/lists/list/workspace/constants";
import { LOG_CATEGORIES } from "@/constants/logs";

/**
 * Hooks
 */
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { useDialerListQueueTableDataExtension } from "@/modules/pipeline/lists/list/workspace/dialer-extension/use-dialer-list-queue-table-data-extension";

/**
 * Utils
 */
import { clsxMerge } from "shared/lib/helpers";
import { isArrayEqual } from "shared/lib/helpers/utils";
import { selectorSyncWithDialerList } from "@/modules/pipeline/lists/list/workspace/dialer-extension/selector";

import { transformRequestParamsToSortingState } from "@/modules/pipeline/lists/data-table/utils";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { dd } from "@/helpers/datadog";

/**
 * Components
 */
import { LeadListsWorkspaceTableActions } from "@/modules/pipeline/lists/list/workspace/table/actions";
import InfiniteScrollingDataTable from "@/modules/pipeline/lists/data-table/infinite-scrolling-table";
import { useTableSorting } from "./hooks/use-table-sorting";
import { SessionStorage } from "@/helpers/session-storage";
import { useLatestValue } from "shared/lib/hooks/use-latest-value";
import { setListTableHeaderOffsetTop } from "@/modules/pipeline/lists/list/workspace/table/utils";
import { useGlobalContext } from "@/hooks/use-global-context";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { LeadListsTableFilters as ListFilters } from "./filters";
import { DataTableColumnVisibilityFilter } from "./column-visibility-filter";
import { useUpdateContactsByAccountId } from "@/modules/pipeline/lists/list/queries/mutations";
import {
  getListDetailsQueryKey,
  LISTS_QUERY_KEY,
} from "@/modules/pipeline/lists/list/queries/keys";
import {
  LIST_SUBSECTIONS_MAP,
  LISTS_WITH_PROTECTED_LEADS,
} from "shared/lib/constants/lists";
import { PipelineListContactI } from "shared/lib/interfaces/lists";

export const LeadListsWorkspaceTable: FC<LeadListsWorkspaceTablePropsI> = ({
  isNurtureList,
  onChangeFilters,
}) => {
  const {
    isEnabled: isGlobalDialer,
    leadsDialing,
    leadsProcessed,
  } = useDialerGlobalContext();

  const {
    activeListId,
    activeList,
    filters,
    listDetails,
    isSearchMode,
    isSystemList,
    searchContactsAPI: searchApi,
  } = useListsContext();

  const { sortParams, onSort } = useTableSorting(activeListId);

  const activeListIdRef = useLatestValue(activeListId);
  const filtersRef = useLatestValue(filters);
  const isNurtureListRef = useLatestValue(isNurtureList);
  const isSystemListRef = useLatestValue(isSystemList);
  const sortParamsRef = useLatestValue(sortParams);

  const { currentCampaign } = useGlobalContext();

  const tableRef = useRef<Table<PipelineListContactI>>(null);

  /**
   * NOTE
   *
   * When sync between table data and dialer leads queue happens
   * we want to delay visual table updates to avoid disturbance in the UI
   */
  const isSyncRef = useRef<boolean>(false);

  /**
   * STATE VARIABLES
   */
  const [rowSelection, setRowSelection] = useState<number[]>([]);
  const [tableContacts, setTableContacts] = useState<PipelineListContactI[]>(
    []
  );

  /**
   * DATA FETCHING
   */
  const rawTableApi = useFetchListContacts({
    listId: activeListId,
    sortParams,
    filters,
    isNurtureList,
    isSystemList,
  });

  const apiUpdateContactsByAccountId = useUpdateContactsByAccountId();
  const api = useMemo(
    () => (isSearchMode ? (searchApi as SearchContactsApiI) : rawTableApi),
    [isSearchMode, searchApi, rawTableApi]
  );
  const apiRef =
    useLatestValue<
      UseInfiniteQueryResult<
        InfiniteData<AxiosResponse<GetListResponseI, any>, unknown>,
        Error
      >
    >(api);

  useEffect(() => {
    // Update the table header offset top when contacts change
    if (tableContacts) {
      setListTableHeaderOffsetTop();
    }
  }, [tableContacts]);

  const selectedContacts = useMemo(
    () =>
      rowSelection.map(
        (contactIndexInData) => tableContacts[contactIndexInData]
      ),
    [tableContacts, rowSelection]
  );

  const isProtectedLeads = useMemo(
    () =>
      !!activeList?.list_type &&
      LISTS_WITH_PROTECTED_LEADS.includes(activeList?.list_type),
    [activeList?.list_type]
  );

  const isSortable = useMemo(
    () => !isSearchMode && !isGlobalDialer,
    [isSearchMode, isGlobalDialer]
  );

  /**
   * EVENTS HANDLING
   */

  const handleSort = onSort(() => {
    tableRef.current?.resetRowSelection();
  });

  const handleOnClickRow = !isGlobalDialer
    ? (_: MouseEvent, row: Row<PipelineListContactI>) => {
        dd.rum.log(`${LOG_CATEGORIES.WIDGET} - triggered`, {
          data: {
            filePath: `/modules/pipeline/lists/list/workspace/table/index.tsx`,
          },
        });

        widgets.open({
          id: WIDGETS.MAXED_DIALER,
          config: {
            campaignId: row.original.campaign_id,
            accountId: row.original.account_id,
          },
        });
      }
    : () => {};

  /**
   * DIALER EXTENSION
   */
  useDialerListQueueTableDataExtension({
    apiRef,
    filters,
    onChangeFilters,
    listDetails,
    setTableContacts,
  });

  /**
   * HANDLING DATA SETTING
   */

  useEffect(() => {
    /**
     * Due to list object mutations by account details reload there is a probability for contact duplications to happen
     * we always need to keep unique contacts but the one that are down in the list are by default
     * newer as loading happens on user's scroll down
     */
    const allContacts =
      api.data?.pages
        ?.flatMap((page) => page.data?.contacts)
        ?.filter(Boolean) || [];

    const tableData = Array.from(
      allContacts
        .reduce(
          (contactsMap, c) => contactsMap.set(c.contact_id, c),
          new Map<string, PipelineListContactI>()
        )
        .values()
    );

    if (isGlobalDialer) {
      setTableContacts(tableData);
      return;
    }

    setTableContacts((oldLeads) => {
      const newLeads = selectorSyncWithDialerList({
        data: tableData,
        leadsDialing,
        leadsProcessed,
      });

      const isEqual = isArrayEqual(newLeads, oldLeads);

      return isEqual ? oldLeads : newLeads;
    });
  }, [api.data]);

  useEffect(() => {
    if (activeList || isSearchMode) {
      tableRef.current?.resetRowSelection();
    }
  }, [activeListId, isSearchMode]);

  /**
   * Listen to events that require filtering or adding more leads
   */
  useEffect(() => {
    const handleAccountUpdate = (
      event: CustomEvent<AccountReloadCompleteDataI>
    ) => {
      (async () => {
        isSyncRef.current = true;

        // Invalidate list details to get the most recent counters for cold/nurture contacts
        if (activeListIdRef.current) {
          queryClient.invalidateQueries({
            queryKey: [LISTS_QUERY_KEY],
            exact: true,
          });

          queryClient.invalidateQueries({
            queryKey: getListDetailsQueryKey(activeListIdRef.current),
            exact: true,
          });
        }

        const { detail: data } = event;

        apiUpdateContactsByAccountId.mutateAsync({
          reactQueryKeys: {
            listId: activeListIdRef.current,
            sortParams: sortParamsRef.current,
            filters: filtersRef.current,
            isNurtureList: isNurtureListRef.current,
            isSystemList: isSystemListRef.current,
          },
          request: {
            campaignId: currentCampaign?.id as string,
            listId: activeListIdRef.current,
            accountId: data?.account?.id as string,
            searchQueryParams: {
              list_subsection: filtersRef.current
                ?.list_subsection as ValueOfObjectFields<
                typeof LIST_SUBSECTIONS_MAP
              >,
            },
          },
        });

        await wait(1000);
        isSyncRef.current = false;
      })();
    };

    //TODO improve typescript
    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.RELOAD_COMPLETE,
      handleAccountUpdate as EventHandler<any>
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.RELOAD_COMPLETE,
        handleAccountUpdate as EventHandler<any>
      );
    };
  }, []);

  const initialTableState: InitialTableState = useMemo(() => {
    const SS = new SessionStorage();

    const sorting = SS.getListSorting(activeListId);

    return sortParams
      ? { sorting: transformRequestParamsToSortingState(sorting) }
      : {};
  }, []);

  /**
   * NOTE
   *
   * We want to make sure that we show only final updates
   * and not temporal one during list sync on Account Details update
   */
  const [debouncedTableData, setdebouncedTableData] = useState<
    PipelineListContactI[]
  >([]);
  const updateTableData = (tableContacts: PipelineListContactI[]) => {
    setdebouncedTableData(tableContacts);
  };
  const setDebouncedTableData = _debounce(updateTableData, 500);
  useEffect(() => {
    if (isGlobalDialer && !isSyncRef.current) {
      setDebouncedTableData(tableContacts);
    }
  }, [tableContacts, isGlobalDialer]);

  const isDialerActionsDisabled = useMemo(() => {
    if (
      filters?.list_subsection === LIST_SUBSECTIONS_MAP.COLD_CALL &&
      (listDetails?.number_available_contacts_to_call_cold || 0) === 0
    ) {
      return true;
    }

    if (
      filters?.list_subsection === LIST_SUBSECTIONS_MAP.NURTURE &&
      (listDetails?.number_available_contacts_to_call_nurture || 0) === 0
    ) {
      return true;
    }

    /**
     * In the scenario of system list neither COLD_CALL or NURTURE list type is selected
     * we check if both available_contacts values equals to 0
     */
    if (
      listDetails?.number_available_contacts_to_call_cold === 0 &&
      listDetails?.number_available_contacts_to_call_nurture === 0
    ) {
      return true;
    }
  }, [
    listDetails?.number_available_contacts_to_call_nurture,
    listDetails?.number_available_contacts_to_call_cold,
    filters?.list_subsection,
  ]);

  return (
    <>
      {/*@ts-ignore*/}
      <Portal node={document.getElementById(LIST_ACTIONS_SLOT_ID)}>
        <div className="flex justify-between">
          <LeadListsWorkspaceTableActions
            ref={tableRef}
            selectedContacts={selectedContacts}
            activeListId={activeListId}
            isExclusiveList={activeList?.is_exclusive_list}
            isSearchMode={isSearchMode}
            disableLeadRemoval={isProtectedLeads}
            isDialerActionsDisabled={isDialerActionsDisabled}
            onChangeFilters={onChangeFilters}
          />

          <div className="flex items-center gap-7">
            <ListFilters />
            <DataTableColumnVisibilityFilter />
          </div>
        </div>
      </Portal>

      {/* 52 px is padding for the fixed footer */}
      <div className="pb-[52px]">
        <InfiniteScrollingDataTable
          listId={activeListId}
          ref={tableRef}
          isSelectable={!isGlobalDialer}
          isDefaultMessageHidden
          isSortable={isSortable}
          tableClassName="bg-white"
          isLoading={api.isFetching}
          hasMoreRows={api.isPending || api.hasNextPage}
          hasError={api.isError}
          isEmpty={
            api.isSuccess && api.data?.pages[0].data?.contacts.length === 0
          }
          reload={api.refetch}
          fetchNextPage={api.fetchNextPage}
          onSort={handleSort}
          initialState={initialTableState}
          onSelect={setRowSelection}
          data={isGlobalDialer ? debouncedTableData : tableContacts}
          headerCellClassName={clsxMerge(
            "sticky z-10 bg-white",
            LIST_TABLE_HEADER_CELL_CLASSNAME
          )}
          rowClassName={(row) =>
            clsxMerge({
              "bg-[#EEF3FF]": row.original.has_unread_notification,
            })
          }
          cellClassName={(cell) =>
            clsxMerge({
              "font-bold":
                cell.row.original.has_unread_notification &&
                [LIST_TABLE_COLUMNS.NAME, LIST_TABLE_COLUMNS.TITLE].includes(
                  cell.column.id as any
                ),
            })
          }
          onClickRow={handleOnClickRow}
        />
      </div>
    </>
  );
};
