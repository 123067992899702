import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import wait from "wait";

import { clsxMerge } from "shared/lib/helpers";
import { PhoneRingingIcon } from "shared/ui/icons";
import { FeatureIntroTooltip } from "shared/ui/feature-intro/tooltip-guide";

import { GetListsRequestFilterParamsI } from "@/api/routes/list";
import { LOG_CATEGORIES } from "@/constants/logs";
import { useWebsocketsContext } from "@/context/websockets-context";
import { dd } from "@/helpers/datadog";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { LISTS_START_CALLING_BUTTON_ID } from "@/constants/element-ids";
import { useListCallingTour } from "@/components/modules/tours/list-calling/hooks";

const DialerButtonToMemo = ({
  pipelineListId,
  disabled,
  onChangeFilters,
}: {
  pipelineListId?: string;
  disabled?: boolean;
  onChangeFilters: Dispatch<
    SetStateAction<GetListsRequestFilterParamsI | undefined>
  >;
}) => {
  const { isAuthorized: isWSAuthorized } = useWebsocketsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { tooltips, isStepVisible, markStepAsCompleted } = useListCallingTour();

  const {
    isEnabled: isDialerActive,
    isSessionClosing,
    startSession,
    endSession,
  } = useDialerGlobalContext();

  const isBtnDisabled =
    isLoading || isSessionClosing || disabled || !isWSAuthorized;

  /**
   * NOTE
   *
   * On start dialing experience system fetches available to call contacts
   * Waiterdon't track success of this action but we will in the future
   * We expect new contacts to load in less then a seconds
   * And ONLY THEN we initiate list dialing experience
   *
   * When session ends filter is set to default value
   */
  const handleOnDialerSwitch = useCallback(() => {
    (async () => {
      setIsLoading(true);

      if (!isDialerActive) {
        onChangeFilters((filters) => ({
          ...filters,
          filter_available_to_call: true,
        }));

        await wait(1000);
        startSession(pipelineListId as string);
        await wait(1000);

        markStepAsCompleted(LISTS_START_CALLING_BUTTON_ID);
      } else {
        endSession();
        onChangeFilters((filters) => ({
          ...filters,
          filter_available_to_call: false,
        }));

        await wait(1000);
      }

      setIsLoading(false);
    })();
  }, [isDialerActive, pipelineListId]);

  useEffect(() => {
    if (!isLoading && !isSessionClosing) {
      if (disabled) {
        dd.log(
          `${LOG_CATEGORIES.DIALER_LIST}[START_CALLING_BUTTON] - disabled; reason: no leads`
        );
      }

      if (!isWSAuthorized) {
        dd.log(
          `${LOG_CATEGORIES.DIALER_LIST}[START_CALLING_BUTTON] - disabled; ws not authorized `
        );
      }
    }
  }, [isLoading, isSessionClosing, disabled, isWSAuthorized]);

  return (
    <>
      <button
        id={LISTS_START_CALLING_BUTTON_ID}
        className={clsxMerge(
          "btn-ae-s",
          "text-white",
          !isDialerActive &&
            !isBtnDisabled &&
            !isLoading &&
            !isSessionClosing &&
            "app-gradient",
          isDialerActive ? "btn-ae-error" : "btn-ae-default",
          (isLoading || isSessionClosing) && "loading"
        )}
        disabled={isBtnDisabled}
        onClick={handleOnDialerSwitch}
      >
        <PhoneRingingIcon className="mr-1.5 h-4 w-4" />
        {isDialerActive ? "Stop" : "Start"} calling
      </button>

      {isStepVisible(LISTS_START_CALLING_BUTTON_ID) && !isBtnDisabled && (
        <FeatureIntroTooltip
          referenceId={LISTS_START_CALLING_BUTTON_ID}
          {...tooltips[LISTS_START_CALLING_BUTTON_ID]}
        />
      )}
    </>
  );
};

/**
 *
 * The DialerButton component is a button that manages the dialing session in a dialing application.
 * It takes an optional pipelineListId as a prop and uses the useState hook to manage a loading state.
 *
 * @param props
 * @param {string} props.pipelineListId - required
 * @returns Start Calling button
 */
export const DialerButton = memo(DialerButtonToMemo);
