import { FC, useMemo } from "react";
import _max from "lodash/max";

import { clsxMerge } from "shared/lib/helpers";
import { ListSelectorMenuCategory } from "./category";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { ListPillI } from "shared/lib/interfaces/lists";
import { LIST_SELECTOR_MENU_ID } from "@/constants/element-ids";

interface ListSelectorMenuPropsI extends PropsWithClassNameI {
  items: ListPillI[] | undefined;
  activeListId: string | undefined | null;
  setActiveListId: (newActiveListId: string) => void;
  onClose: () => void;
}

export const ListSelectorMenu: FC<ListSelectorMenuPropsI> = ({
  className,
  items,
  activeListId,
  setActiveListId,
  onClose,
}) => {
  const { fixedLists, customLists } = useMemo(() => {
    const fixedLists: ListPillI[] = [];
    const customLists: ListPillI[] = [];

    items?.forEach((item) => {
      if (item.is_default_list) {
        fixedLists.push(item);
      } else {
        customLists.push(item);
      }
    });

    return { fixedLists, customLists };
  }, [items]);

  const isDataAvailable = useMemo(
    () => [fixedLists, customLists].some((list) => list.length > 0),
    [fixedLists, customLists]
  );

  const listHeightPx = useMemo(() => {
    const maxListsInAnyColumn =
      _max([fixedLists, customLists].map((collection) => collection.length)) ||
      0;

    return (maxListsInAnyColumn + 1) * 50;
  }, [fixedLists, customLists]);

  const handleSelectList = (listId: string) => {
    onClose();

    if (listId !== activeListId) {
      setActiveListId(listId);
    }
  };

  return (
    <div
      id={LIST_SELECTOR_MENU_ID}
      className={clsxMerge(
        "absolute left-0 top-full z-50",
        "animate-fadein rounded bg-white shadow-[0px_0px_4px_4px_rgba(0,0,0,0.06)]",
        isDataAvailable
          ? "grid max-h-[850px] w-[462px] grid-cols-2 grid-rows-1"
          : "w-max px-3 py-2",
        className
      )}
      style={{ height: isDataAvailable ? `${listHeightPx}px` : "auto" }}
    >
      {isDataAvailable ? (
        <>
          <ListSelectorMenuCategory
            categoryName="Smart list"
            items={fixedLists}
            activeListId={activeListId}
            onSelect={handleSelectList}
          />

          <ListSelectorMenuCategory
            categoryName="Custom lists"
            items={customLists}
            activeListId={activeListId}
            onSelect={handleSelectList}
          />
        </>
      ) : (
        <span className="text-[#666] typography-body-5-medium">
          You don't have any lists yet.
        </span>
      )}
    </div>
  );
};
