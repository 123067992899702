import { createElement, FC, useMemo } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { AccountUserListTypeI } from "shared/lib/interfaces/account";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import {
  LIST_FEEDBACK_STATUS_PILL_VARIANTS,
  LIST_STATUS_PILL_LABEL_OVERRIDES,
  LIST_STATUS_PILL_VARIANTS,
  LIST_TYPES,
  SYSTEM_LIST_TITLES,
} from "shared/lib/constants/lists";
import { ListStatusMeetingFeedbackI } from "shared/lib/interfaces/dispositions";
import {
  LIST_STATUS_MEETING_FEEDBACK_LABELS,
  LIST_STATUS_MEETING_FEEDBACK_LABELS_GLENX,
} from "shared/lib/constants/dispositions";

interface ListStatusPillPropsI extends PropsWithClassNameI {
  listType: AccountUserListTypeI;
  meetingFeedback?: ListStatusMeetingFeedbackI;
  isGlenXProduct?: boolean;
}

export const ListStatusPill: FC<ListStatusPillPropsI> = ({
  className,
  listType,
  meetingFeedback,
  isGlenXProduct = false,
}) => {
  const isMeetingFeedback =
    meetingFeedback && listType === LIST_TYPES.FEEDBACK_RECEIVED;

  // const typedListType = listType as keyof typeof LIST_STATUS_PILL_VARIANTS;
  const pillConfig = useMemo(() => {
    if (isMeetingFeedback) {
      return LIST_FEEDBACK_STATUS_PILL_VARIANTS[meetingFeedback];
    }

    return LIST_STATUS_PILL_VARIANTS[
      listType as keyof typeof LIST_STATUS_PILL_VARIANTS
    ];
  }, [isMeetingFeedback, meetingFeedback, listType]);

  const listName = useMemo(() => {
    if (listType in LIST_STATUS_PILL_LABEL_OVERRIDES) {
      return LIST_STATUS_PILL_LABEL_OVERRIDES[
        listType as keyof typeof LIST_STATUS_PILL_LABEL_OVERRIDES
      ];
    }

    if (isMeetingFeedback) {
      const meetingFeedbackLabelContainer = isGlenXProduct
        ? LIST_STATUS_MEETING_FEEDBACK_LABELS_GLENX
        : LIST_STATUS_MEETING_FEEDBACK_LABELS;
      return meetingFeedbackLabelContainer[meetingFeedback];
    }

    return listType === LIST_TYPES.CALLBACKS
      ? "Nurture"
      : SYSTEM_LIST_TITLES[listType as keyof typeof SYSTEM_LIST_TITLES];
  }, [isMeetingFeedback, listType, meetingFeedback]);

  if (listType in LIST_STATUS_PILL_VARIANTS || isMeetingFeedback) {
    return (
      <div
        className={clsxMerge(
          "flex w-fit items-center rounded px-2 py-1",
          "brand-typography-h7 gap-1.5",
          pillConfig.bg,
          pillConfig.color,
          className
        )}
      >
        {createElement(pillConfig.icon, {
          className: clsxMerge(pillConfig?.color, "w-4 h-4"),
        })}

        {listName}
      </div>
    );
  }

  return <span className="text-black/60">N/A</span>;
};
