import { getS3AssetPath } from "shared/lib/helpers/s3";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";

export const OnboardingSetupModalHeader = () => {
  const { isFinishedSetup, isSingleUserLicense } = useOnboardingStatus();

  const imgSrc = isFinishedSetup
    ? "platform/onboarding/celebrate.webp"
    : "platform/onboarding/rocket_with_stars.webp";

  const title = isFinishedSetup
    ? "Nice work! You're ready to start building some pipe"
    : "Finish setting up your account & unlock even more value";

  return (
    <>
      <div className="mb-6 flex items-center justify-center">
        <img src={getS3AssetPath(imgSrc)} alt="" className="h-auto w-[154px]" />
      </div>

      <div className="mb-5 typography-header-5-semibold">{title}</div>

      <div className="mb-4 typography-body-4">
        {isSingleUserLicense ? (
          <span>
            We'd like to offer you even more value. Complete the tasks below and
            we'll give you <b>2 more weeks free</b>.
          </span>
        ) : (
          "Complete the tasks below to start using GlenX."
        )}
      </div>

      <div className="gradient-text mb-3 typography-header-8-semibold">
        {isSingleUserLicense
          ? "Get 2 more weeks free when you:"
          : "2 steps remaining"}
      </div>
    </>
  );
};
