import { FC, memo } from "react";

import { DayJs } from "shared/lib/helpers/date";

export const LeadCooldownNotificationRaw: FC<{ date: string }> = ({ date }) => {
  const availableToCallFormattedDate = DayJs(date).format("M/DD [at] h:mmA");

  return (
    <div className="text-white">
      <span className="rounded-lg p-1 px-2">
        This lead has been called recently. Can call again on{" "}
        {availableToCallFormattedDate}
      </span>
    </div>
  );
};

export const LeadCooldownNotification = memo(LeadCooldownNotificationRaw);
