import { FC, useMemo } from "react";
import _noop from "lodash/noop";
import { Info } from "lucide-react";

import {
  ControllableComponentPropsI,
  PropsWithClassNameI,
} from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui/tooltip";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";
import {
  LIST_SUBSECTION_DESCRIPTIONS_MAP,
  LIST_SUBSECTIONS_LABEL_MAP,
  LIST_SUBSECTIONS_MAP,
} from "shared/lib/constants/lists";
import { ListSubsectionI } from "shared/lib/interfaces/lists";

const BG_CLASSNAMES = {
  [LIST_SUBSECTIONS_MAP.COLD_CALL]:
    "bg-gradient-to-r from-[#363FD0] to-[#99B2FF]",
  [LIST_SUBSECTIONS_MAP.NURTURE]:
    "bg-gradient-to-r from-[#FF7B1D] to-[#DA1C00]",
};

interface ListTypeButtonPropsI
  extends ControllableComponentPropsI<ListSubsectionI>,
    PropsWithClassNameI {
  id?: string;
  isDisabled?: boolean;
  subsection: ListSubsectionI;
  numerator: number | undefined;
  denominator: number | undefined;
  tooltip?: {
    title: string;
    content: string;
  };
}

export const ListSubsectionButton: FC<ListTypeButtonPropsI> = ({
  id,
  value,
  onChange,
  subsection,
  numerator,
  denominator,
  tooltip,
  className,
  isDisabled = false,
}) => {
  const isActive = value === subsection;

  const leadsCount = useMemo(
    () =>
      numerator === denominator
        ? numerator
        : `${numerator || 0} / ${denominator || 0}`,
    [numerator, denominator]
  );

  const content = (
    <div
      id={id}
      className={clsxMerge(
        "cursor-pointer border-2 border-transparent",
        "w-[220px] rounded-xl p-3",
        "transition-all duration-300",
        "typography-body-4 relative",
        {
          "border-[#4474E3] text-white": isActive,
          [BG_CLASSNAMES[subsection]]: isActive,
          "bg-white hover:scale-[0.99] hover:border-[#D9D9D960]": !isActive,
        },
        {
          "opacity-50": isDisabled,
        },
        className
      )}
      onClick={isDisabled ? _noop : () => onChange(subsection)}
    >
      <div
        className={clsxMerge("mb-1", {
          "text-black/60": !isActive,
        })}
      >
        {LIST_SUBSECTIONS_LABEL_MAP[subsection]} leads
      </div>

      <div className="typography-body-2 mb-1 flex h-9 items-center">
        {leadsCount ?? <LoadingSpinner />}
      </div>

      <div
        className={clsxMerge("typography-body-6", {
          "text-black/40": !isActive,
        })}
      >
        {LIST_SUBSECTION_DESCRIPTIONS_MAP[subsection]}
      </div>

      {isActive && (
        <div
          className={clsxMerge(
            "absolute right-2 top-2",
            "px-2 py-1",
            "typography-body-5 rounded-md bg-black text-white/80",
            "animate-fadein"
          )}
        >
          Selected
        </div>
      )}
    </div>
  );

  return tooltip ? (
    <Tooltip showDelay={50} placement="bottom">
      <TooltipTrigger className="text-start">{content}</TooltipTrigger>
      <TooltipContent className="typography-body-5-medium flex max-w-[320px] gap-2.5 p-4">
        <div>
          <Info size={16} className="mt-[1px] text-white" />
        </div>
        <div className="flex flex-col gap-1 text-white">
          <h3 className="typography-body-3-medium">{tooltip.title}</h3>
          <p className="typography-body-4">{tooltip.content}</p>
        </div>
      </TooltipContent>
    </Tooltip>
  ) : (
    content
  );
};
