import { FC } from "react";
import { BrainCircuit, Phone, Send, UserSearch } from "lucide-react";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { clsxMerge } from "shared/lib/helpers";
import { Logo } from "shared/ui/logo";
import { LOGO_VARIATION } from "shared/ui/logo/constants";
import {
  useGlobalContext,
  useIsSingleUserLicense,
} from "@/hooks/use-global-context";
import { Button } from "shared/ui/shadcn/button";
import { useLocalStorage } from "@/context/local-storage";

export const LIST_TOUR_MODAL_ID = "list-tour-modal";

interface ListTourModalPropsI {
  onEnterTour: () => void;
}

const ICON_CLASSNAME = "w-3.5 text-[#CC3997]";

const INFO_SECTIONS_TEAM_LICENSE = [
  {
    title: "Dial",
    description:
      "Our smart dialer uses AI to dial multiple prospects at once to increase your opportunities to connect",
    icon: <Phone className={ICON_CLASSNAME} />,
  },
  {
    title: "Email",
    description:
      "Leverage our AI agents and AI-assisted email copy generator to send highly researched emails to leads and nurture them",
    icon: <Send className={ICON_CLASSNAME} />,
  },
  {
    title: "Research",
    description:
      "Our built in lead finder and enricher discovers more leads and more contact info with the click of a button",
    icon: <UserSearch className={ICON_CLASSNAME} />,
  },
];

const INFO_SECTIONS_SINGLE_USER_LICENSE = [
  {
    title: "Dial",
    description:
      "Our smart dialer uses AI to dial multiple prospects at once to increase your opportunities to connect",
    icon: <Phone className={ICON_CLASSNAME} />,
  },
  {
    title: "Email",
    description:
      "Leverage our AI agents and AI-assisted email copy generator to send highly researched emails to leads and nurture them",
    icon: <Send className={ICON_CLASSNAME} />,
  },
  {
    title: "Research",
    description:
      "Our built in lead finder and enricher discovers more leads and more contact info with the click of a button",
    icon: <UserSearch className={ICON_CLASSNAME} />,
  },
  {
    title: "Knowledge",
    description: "Our AI gets better overtime the more you use it",
    icon: <BrainCircuit className={ICON_CLASSNAME} />,
  },
];

const ListTourModalContent: FC<ListTourModalPropsI & ModalChildrenFnPropsI> = ({
  onEnterTour,
  handleClose: handleCloseModal,
}) => {
  const { glencocoUser: user } = useGlobalContext();
  const isSingleLicenseUser = useIsSingleUserLicense();
  const localStorage = useLocalStorage();

  const infoSections = isSingleLicenseUser
    ? INFO_SECTIONS_SINGLE_USER_LICENSE
    : INFO_SECTIONS_TEAM_LICENSE;

  const handleEnterTour = () => {
    handleClose();
    onEnterTour();
  };

  const handleSkipTour = () => {
    handleClose();
  };

  const handleClose = () => {
    localStorage.listTourModalVisitedDate = new Date().toString();
    handleCloseModal();
  };

  return (
    <div className="grid grid-cols-8 gap-3">
      <div
        className={clsxMerge(
          "col-span-3 flex h-full flex-col-reverse",
          "rounded-xl bg-[linear-gradient(180deg,#CC3997_43.09%,#4B61AB_95.75%)] px-6 pb-16"
        )}
      >
        <div className="flex flex-col gap-2">
          <Logo variation={LOGO_VARIATION.GLENX_FULL_WHITE} />
          <p className="text-[#E2E8F0] typography-body-4">
            Get to know glenX and the AI powered experiences helping to support
            top sales teams
          </p>
        </div>
      </div>

      <div className="col-span-5 flex flex-col gap-4 pl-3">
        <div>
          <div className="mb-1 typography-header-5-semibold">
            Welcome {user?.first_name},
          </div>
          <div className="text-[#64748B] typography-body-4">
            GlenX is your premier sales tooling platform. Take a tour of some of
            our top features.
          </div>
        </div>

        {infoSections.map(({ title, description, icon }, index) => (
          <div
            key={index}
            className={clsxMerge(
              "flex flex-col",
              "animate-enter-from-bottom fill-mode-backwards",
              `delay-${index * 100}`
            )}
          >
            <div className="mb-0.5 flex items-center gap-2">
              {icon}
              <h5 className="text-black typography-body-4-medium">{title}</h5>
            </div>
            <p className="text-[#64748B] typography-body-4">{description}</p>
          </div>
        ))}

        <div className="mt-3 flex gap-2 self-end">
          <Button variant="outline" onClick={handleSkipTour}>
            Skip
          </Button>

          <Button onClick={handleEnterTour}>Take tour</Button>
        </div>
      </div>
    </div>
  );
};

export const ListTourModal: FC<ListTourModalPropsI> = (props) => (
  <Modal
    id={LIST_TOUR_MODAL_ID}
    modalBoxClassName="w-[640px] rounded-md"
    closeIconClassName="text-black/40"
  >
    {(modalProps) => {
      return <ListTourModalContent {...props} {...modalProps} />;
    }}
  </Modal>
);
