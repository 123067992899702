import { DayJs } from "shared/lib/helpers/date";
import {
  LIST_HEADER_AND_ACTIONS_CONTAINER_ID,
  LIST_TABLE_HEADER_CELL_CLASSNAME,
} from "@/modules/pipeline/lists/list/workspace/constants";

export const isCooldown = (date?: string) => {
  if (!date) {
    return false;
  }

  const now = DayJs();

  return now.isBefore(DayJs(date));
};

export const isDatePast = (date?: string) => {
  if (!date) return false;

  return DayJs(date).isBefore(DayJs());
};

export const isDateToday = (date?: string) => {
  if (!date) return false;

  return DayJs(date).isToday();
};

/**
 * A workaround util to fix the issue with the list table header not being
 * correctly positioned, because CSS "position: sticky" doesn't support stacking
 * sticky elements, and elements above the table header may vary in height, for
 * example when:
 * 1) Switching to a search mode
 * 2) Expanding and collapsing the list description
 */
export const setListTableHeaderOffsetTop = (delayMilliseconds = 50) => {
  setTimeout(() => {
    // Collection of all THs in the list table header TR
    const listTableHeaderElements = document.querySelectorAll(
      `.${LIST_TABLE_HEADER_CELL_CLASSNAME}`
    );

    if (listTableHeaderElements.length > 0) {
      // List header and actions container height - the element with a varying
      // height
      const listHeaderAndActionsContainerHeight =
        document.getElementById(LIST_HEADER_AND_ACTIONS_CONTAINER_ID)
          ?.clientHeight || 0;

      const listTableHeaderElementsTop =
        listHeaderAndActionsContainerHeight - 2;

      listTableHeaderElements.forEach((element) => {
        (
          element as HTMLTableCellElement
        ).style.top = `${listTableHeaderElementsTop}px`;
      });
    }
  }, delayMilliseconds);
};
