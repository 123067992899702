export const INFO_MODAL_DIALING_LIST_COMPLETE_ID =
  "info_modal_dialing_list_complete";

export const LIST_TABLE_COLUMNS = {
  NAME: "contact_name",
  TITLE: "contact_title",
  NUMBER_OF_DIALS: "number_of_dials",
  ACCOUNT: "account_name",
  STATE: "account_state",
  STATUS: "status",
  LAST_CALLED: "last_called",
  NEXT_TOUCH_TIME: "callback_time",
  DIALS: "number_of_calls",
  LAST_DIALED: "last_called",
  LAST_CONVERSATION: "last_conversation",
  TIMEZONE: "timezone",
  ACCOUNT_CITY: "account_city",
  HEADCOUNT: "headcount",
  REVENUE: "revenue",
  NUMBER_OF_CALLS: "number_of_calls",
  NUMBER_OF_CONVERSATIONS: "number_of_conversations",
  CALLBACK_TIME: "callback_time",
  MEETING_TIME: "meeting_time",
} as const;
