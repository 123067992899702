import { FC, CSSProperties } from "react";
import { BookCheck } from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { Separator } from "shared/ui/shadcn/separator";

import { useListTourContext } from "@/modules/tours/list/context";
import { ONBOARDING_SETUP_MODAL_ID } from "@/components/modals/onboarding-setup-modal";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";

const ListTourButton = () => {
  const { openTour, isTourOpen, closeTour } = useListTourContext();

  return (
    <button
      className={clsxMerge(
        "btn-ae-default app-gradient transition-opacity duration-300",
        {
          "opacity-0": isTourOpen,
        }
      )}
      onClick={isTourOpen ? closeTour : openTour}
    >
      Take tour
    </button>
  );
};

const FinishSetupButton = () => {
  return (
    <button
      className={clsxMerge(
        "btn-ae-light btn flex",
        "flex-nowrap items-center gap-2",
        "border-solid border-[#E4E4E7] typography-body-4-medium",
        "animate-pulse-alt"
      )}
      style={
        {
          "--pulse-alt-start-color": "#CC3997",
          "--pulse-alt-end-color": "rgba(0,0,0,0)",
        } as CSSProperties
      }
      onClick={() => modalHelpers.open(ONBOARDING_SETUP_MODAL_ID)}
    >
      <BookCheck className="size-4" />
      Finish setup
    </button>
  );
};

export const ListOnboardingActionsSingleUserLicense: FC<
  PropsWithClassNameI
> = ({ className }) => {
  const { isTourCompleted } = useListTourContext();
  const { isFinishedSetup } = useOnboardingStatus();

  if (isTourCompleted && isFinishedSetup) {
    return null;
  }

  return (
    <div className={clsxMerge("flex w-[150px] items-center gap-2", className)}>
      <Separator orientation="vertical" className="mr-1 h-[90px]" />

      <div className="flex w-full flex-col justify-center gap-2">
        {!isTourCompleted && <ListTourButton />}
        {!isFinishedSetup && <FinishSetupButton />}
      </div>
    </div>
  );
};

export const ListOnboardingActionsTeamLicense: FC<PropsWithClassNameI> = ({
  className,
}) => {
  const { isTourCompleted } = useListTourContext();

  if (isTourCompleted) {
    return null;
  }

  return (
    <div className={clsxMerge("flex w-[150px] items-center gap-2", className)}>
      <Separator orientation="vertical" className="mr-1 h-[90px]" />

      <div className="flex w-full flex-col justify-center gap-2">
        <ListTourButton />
      </div>
    </div>
  );
};

export const ListsOnboardingActions: FC<PropsWithClassNameI> = ({
  className,
}) => {
  const { isSingleUserLicense } = useOnboardingStatus();

  return isSingleUserLicense ? (
    <ListOnboardingActionsSingleUserLicense className={className} />
  ) : (
    <ListOnboardingActionsTeamLicense className={className} />
  );
};
