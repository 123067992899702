import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useQueryState } from "nuqs";
import _find from "lodash/find";

import { ListPillI, PipelineListContactI } from "shared/lib/interfaces/lists";
import {
  checkIfNurtureList,
  checkIfSystemList,
  getSortedLists,
} from "shared/lib/helpers/lists";
import { useIsMounted, useLatestValue } from "shared/lib/hooks";
import { LIST_SUBSECTIONS_MAP } from "shared/lib/constants/lists";

import { useSearchQueryParams } from "@/hooks/use-search-query-params";
import { useLocalStorage } from "@/context/local-storage";
import {
  GetListDetailsResponseI,
  GetListsRequestFilterParamsI,
} from "@/api/routes/list";
import {
  FetchListsApiI,
  SearchContactsApiI,
  useFetchLists,
} from "../list/queries/queries";
import { useListWorkspace } from "../list/workspace/hooks/use-list-workspace";
import { useDataTableColumns } from "./hooks/use-data-table-columns";

interface ListsContextValueI {
  activeListId: string;
  activeList: ListPillI | undefined;
  listDetails: GetListDetailsResponseI | undefined;
  isNurtureList: boolean | null;

  // System lists cannot be deleted and their name cannot be changed
  isSystemList: boolean;

  filters: GetListsRequestFilterParamsI | undefined;
  columns: ColumnDef<PipelineListContactI>[];
  columnVisibility: VisibilityState;
  searchTerm: string | undefined;
  isSearchMode: boolean;
  isPendingListDetails: boolean;

  setActiveListId: (value: string) => void;
  resetActiveListId: () => void;
  setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
  onChangeFilters: Dispatch<
    SetStateAction<GetListsRequestFilterParamsI | undefined>
  >;
  setColumnVisibility: Dispatch<SetStateAction<VisibilityState>>;

  listsAPI: FetchListsApiI;
  searchContactsAPI: SearchContactsApiI;
}

const ListsContext = createContext<ListsContextValueI>({
  activeListId: "",
  activeList: undefined,
  listDetails: undefined,
  isNurtureList: null,
  isSystemList: false,

  filters: undefined,
  columns: [],
  columnVisibility: {},
  searchTerm: undefined,
  isSearchMode: false,
  isPendingListDetails: false,

  setActiveListId: () => Promise.resolve(new URLSearchParams()),
  resetActiveListId: () => {},
  setSearchTerm: () => {},
  onChangeFilters: () => {},
  setColumnVisibility: () => {},

  listsAPI: {} as FetchListsApiI,
  searchContactsAPI: {} as SearchContactsApiI,
});

export const useListsContext = () => useContext(ListsContext);

export const ListsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted();
  const localStorage = useLocalStorage();

  /**
   * NOTE:
   * To ensure we get id on the first render from url or localStorage
   */
  const activeListIdFromSearchQuery = useSearchQueryParams("list");
  const [activeListId, setActiveListQueryParam] = useQueryState("list", {
    defaultValue: activeListIdFromSearchQuery || localStorage.activeListId,
  });
  const latestActiveListIdRef = useLatestValue(activeListId);

  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const listsAPI = useFetchLists();

  /**
   * This is critically important to keep parameters which form lists query key
   * as a single state - this is the only way to guarantee that these params are
   * always in sync and avoid duplicated, "dead" queries (with incorrect params).
   */
  const [listStateAccumulator, setListStateAccumulator] = useState({
    activeListId,
    filters: localStorage.getListFilters(activeListId),
    isSystemList: false,
  });

  const {
    isSearchMode,
    searchContactsAPI,
    response: listDetailsResp,
    isPending: isPendingListDetails,
    isFetchListDetailsError,
  } = useListWorkspace({
    listId: activeListId,
    searchTerm: searchTerm,
  });

  const listDetails = listDetailsResp?.data;

  const defaultListId = useMemo(
    () =>
      listsAPI.data && listsAPI.data.data.user_lists.length > 0
        ? getSortedLists(listsAPI.data.data?.user_lists)[0]?.id
        : null,
    [listsAPI.data]
  );

  const activeList = useMemo(
    () => _find(listsAPI?.data?.data.user_lists, { id: activeListId }),
    [listsAPI?.data?.data, activeListId]
  );

  const isNurtureList = useMemo(
    () => checkIfNurtureList(activeList),
    [activeList?.id]
  );

  const resetActiveListId = () => {
    if (defaultListId) {
      setActiveListId(defaultListId);
    }
  };

  const { columnVisibility, setColumnVisibility, columns } =
    useDataTableColumns({
      activeList,
      isSearchMode,
      isNurtureList,
      filters: listStateAccumulator.filters,
    });

  const setActiveListId = (listId: string | undefined | null) => {
    if (!listId) {
      return;
    }

    setListStateAccumulator((prevState) => {
      if (prevState.activeListId === listId) {
        return prevState;
      }

      setActiveListQueryParam(listId);
      localStorage.activeListId = listId;

      const activeList = _find(listsAPI.data?.data.user_lists, { id: listId });

      if (!activeList) {
        return prevState;
      }

      const isDefaultList = activeList.is_default_list;
      const filtersFromStorage = localStorage.getListFilters(activeList.id);

      const initiallyShownSubsection =
        activeList?.nurture_count && activeList?.nurture_count > 0
          ? LIST_SUBSECTIONS_MAP.NURTURE
          : LIST_SUBSECTIONS_MAP.COLD_CALL;

      const filters: GetListsRequestFilterParamsI = {
        ...filtersFromStorage,
        list_subsection: isDefaultList
          ? undefined
          : filtersFromStorage?.list_subsection ?? initiallyShownSubsection,
      };

      return {
        activeListId: listId,
        filters,
        isSystemList: checkIfSystemList(activeList),
      };
    });
  };

  const onChangeFilters: Dispatch<
    SetStateAction<GetListsRequestFilterParamsI | undefined>
  > = useCallback(
    (reducer) => {
      if (reducer && latestActiveListIdRef.current) {
        localStorage.setListFilters(latestActiveListIdRef.current, {
          ...listStateAccumulator.filters,
          ...(typeof reducer === "function"
            ? reducer(listStateAccumulator.filters)
            : reducer),
        });
      }

      setListStateAccumulator((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          ...(typeof reducer === "function"
            ? reducer(prevState.filters)
            : reducer),
        },
      }));
    },
    [listStateAccumulator.filters]
  );

  /**
   * Here we check if nuqs should already have a value for the list query param,
   * but it still doesn't, meaning that we should set an active list ourselves.
   */
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (activeListId) {
      // Ensure that we react to external trial of changing active list id.
      setActiveListId(activeListId);
    } else {
      setActiveListId(localStorage.activeListId || defaultListId);

      if (localStorage.activeListId) {
        localStorage.activeListId = null;
      }
    }
  }, [activeListId, isMounted, defaultListId]);

  useEffect(() => {
    if (isFetchListDetailsError && defaultListId) {
      resetActiveListId();
    }
  }, [isFetchListDetailsError, defaultListId]);

  const providerValue = useMemo(
    () => ({
      activeList,
      listDetails,
      isNurtureList,

      columns,
      columnVisibility,
      searchTerm,
      isSearchMode,
      isPendingListDetails,

      setActiveListId,
      resetActiveListId,
      setSearchTerm,
      onChangeFilters,
      ...listStateAccumulator,
      setColumnVisibility,

      listsAPI,
      searchContactsAPI,
    }),
    [
      listStateAccumulator,
      activeList,
      listDetails,
      isNurtureList,

      columns,
      columnVisibility,
      searchTerm,
      isSearchMode,
      isPendingListDetails,

      setActiveListId,
      resetActiveListId,
      setSearchTerm,
      onChangeFilters,
      setColumnVisibility,
      listsAPI,
      searchContactsAPI,
    ]
  );

  return (
    <ListsContext.Provider value={providerValue}>
      {children}
    </ListsContext.Provider>
  );
};
