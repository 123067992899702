/**
 * INTERFACES
 */
import { ColumnDef } from "@tanstack/react-table";

/**
 * CONSTANTS
 */
import { DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ } from "shared/lib/constants/time";

/**
 * UTILS
 */
import { clsxMerge, safeGetDate } from "shared/lib/helpers";
import {
  calculatePercentage,
  formatMoneyDollars,
  formatNumbers,
} from "shared/lib/helpers/numbers";

/**
 * COMPONENTS
 */
import { ListStatusPill } from "shared/ui/lists/status-pill/list-status-pill";
import { prospectNameCell } from "./prospect-name";
import { DialerStatus } from "./dialer-status";
import { ClockIcon, PhoneIcon } from "@heroicons/react/24/outline";
import {
  isDatePast,
  isDateToday,
} from "@/modules/pipeline/lists/list/workspace/table/utils";
import { LIST_TABLE_COLUMNS } from "@/modules/pipeline/lists/list/constants";
import { ListPillI, PipelineListContactI } from "shared/lib/interfaces/lists";

// This should become just ListTableColumnI after extracting all columns to a map
type ListTableMapColumnI = typeof LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS;

interface ColumnsMapColumnConfigI {
  enableHiding?: boolean;
}

/**
 * All the columns must be eventually extracted to a map to then build columns
 * for different types of table and avoiding using splice function.
 */
export const COLUMNS_MAP: Record<
  ListTableMapColumnI,
  (config?: ColumnsMapColumnConfigI) => ColumnDef<PipelineListContactI>
> = {
  [LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS]: (config) => ({
    id: LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS,
    enableHiding: config?.enableHiding || false,
    header: "Conversations",
    accessorFn: ({ number_of_conversations }) => number_of_conversations,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.number_of_conversations
          ? formatNumbers(row.original.number_of_conversations)
          : "0"}
      </span>
    ),
  }),
} as const;

/**
 * Point of definition of all table headers and cells
 *
 * How to define the content of table header or cell?
 * https://tanstack.com/table/latest/docs/guide/cells#cell-rendering
 */
export const selectDefaultColumns = (
  list: ListPillI | undefined
): ColumnDef<PipelineListContactI>[] => [
  {
    id: LIST_TABLE_COLUMNS.NAME,
    header: "Prospect Name",
    accessorFn: ({ contact_name }) => contact_name,
    cell: prospectNameCell(list),
  },
  {
    id: LIST_TABLE_COLUMNS.TITLE,
    header: "Prospect Title",
    accessorFn: ({ contact_title }) => contact_title,
    cell: ({ row }) => (
      <span>{row.original.contact_title?.trim() || "N/A"}</span>
    ),
  },
  COLUMNS_MAP[LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS](),
  {
    id: LIST_TABLE_COLUMNS.LAST_CONVERSATION,
    enableHiding: false,
    header: "Last conversation",
    accessorFn: ({ last_conversation }) => last_conversation,
    cell: ({ row }) => {
      const lastConversation = row.original.last_conversation;

      return (
        <span className="text-black/60">
          {lastConversation
            ? safeGetDate(
                lastConversation,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ
              )
            : "N/A"}
        </span>
      );
    },
  },
  {
    id: LIST_TABLE_COLUMNS.TIMEZONE,
    enableHiding: true,
    header: "Timezone",
    accessorFn: ({ timezone }) => timezone,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original?.timezone?.trim() || "N/A"}
      </span>
    ),
  },
  {
    id: LIST_TABLE_COLUMNS.ACCOUNT,
    enableHiding: true,
    header: "Account",
    accessorFn: ({ account_name }) => account_name,
    cell: ({ row }) => {
      const accountName = row.original.account_name?.trim() || "N/A";

      return (
        <span title={accountName} className="text-black/60">
          {accountName}
        </span>
      );
    },
  },
  {
    id: LIST_TABLE_COLUMNS.STATE,
    enableHiding: true,
    header: "Account State",
    accessorFn: ({ account_state }) => account_state,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.account_state?.trim() || "N/A"}
      </span>
    ),
  },
  {
    id: LIST_TABLE_COLUMNS.ACCOUNT_CITY,
    enableHiding: true,
    header: "Account City",
    accessorFn: ({ account_city }) => account_city,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.account_city?.trim() || "N/A"}
      </span>
    ),
  },
  {
    id: LIST_TABLE_COLUMNS.HEADCOUNT,
    enableHiding: true,
    header: "Headcount",
    accessorFn: ({ headcount }) => headcount,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.headcount?.trim() || "N/A"}
      </span>
    ),
  },
  {
    id: LIST_TABLE_COLUMNS.REVENUE,
    enableHiding: true,
    header: "Revenue",
    accessorFn: ({ revenue }) => revenue,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.revenue
          ? formatMoneyDollars(row.original.revenue, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : "N/A"}
      </span>
    ),
  },
  {
    id: LIST_TABLE_COLUMNS.NUMBER_OF_DIALS,
    enableHiding: true,
    header: "Number of Dials",
    accessorFn: ({ number_of_calls }) => number_of_calls,
    cell: ({ row }) => (
      <span className="text-black/60">
        {row.original.number_of_calls || "0"}
      </span>
    ),
  },

  {
    id: LIST_TABLE_COLUMNS.LAST_CALLED,
    enableHiding: true,
    header: "Last dialed",
    accessorFn: ({ last_called }) => last_called,
    cell: ({ row }) => {
      const lastTouched = row.original.last_called;

      return (
        <span className="text-black/60">
          {lastTouched
            ? safeGetDate(
                lastTouched,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ
              )
            : "N/A"}
        </span>
      );
    },
  },
  {
    id: LIST_TABLE_COLUMNS.CALLBACK_TIME,
    header: "Callback time",
    enableHiding: true,
    accessorFn: ({ callback_time }) => callback_time,
    cell: ({ row }) => (
      <span>
        {row.original?.callback_time
          ? safeGetDate(
              row.original.callback_time,
              DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ
            )
          : "N/A"}
      </span>
    ),
  },
];

export const UNIQUE_BOOKED_MEETING_LIST_COLUMNS: ColumnDef<PipelineListContactI>[] =
  [
    {
      id: LIST_TABLE_COLUMNS.MEETING_TIME,
      header: "Meeting time",
      accessorFn: ({ meeting_time }) => meeting_time,
      cell: ({ row }) => (
        <span>
          {row.original.meeting_time
            ? safeGetDate(
                row.original.meeting_time,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ
              )
            : "N/A"}
        </span>
      ),
    },
  ];

export const DIALER_STATUS_LIST_COLUMN: ColumnDef<PipelineListContactI> = {
  id: LIST_TABLE_COLUMNS.STATUS,
  enableHiding: true,
  header: "Status",
  cell: DialerStatus,
};

export const STATUS_LIST_COLUMN = (
  list?: ListPillI
): ColumnDef<PipelineListContactI> => ({
  id: LIST_TABLE_COLUMNS.STATUS,
  enableHiding: true,
  header: "Status",
  cell: ({ row }) =>
    list && (
      <ListStatusPill
        isGlenXProduct
        meetingFeedback={row.original?.meeting_feedback}
        listType={list.list_type}
      />
    ),
});

export const COLD_LIST_UNIQUE_COLUMNS: ColumnDef<PipelineListContactI>[] = [
  {
    id: LIST_TABLE_COLUMNS.DIALS,
    header: "Dials",
    meta: {
      tooltip:
        "We automatically remove leads that have not responded after 20 dialing attempts.",
    },
    accessorFn: ({ number_of_calls }) => number_of_calls ?? 0,
    cell: ({ row }) => {
      const dials = row.original?.number_of_calls ?? 0;

      return (
        <div className="flex w-[55px] flex-col items-center gap-1 rounded border p-1 typography-body-5-medium">
          <div className="flex gap-1 text-center">
            <PhoneIcon className="w-3" />
            <span>{dials ?? 0}/20</span>
          </div>

          <div className="h-1 w-full overflow-hidden rounded-full bg-[#ccc]">
            <div
              style={{ width: calculatePercentage(dials, 20) }}
              className={clsxMerge("h-1", {
                "bg-[#27CA4A]": dials < 10,
                "bg-black": dials >= 10 && dials < 16,
                "bg-[#CA3C27]": dials >= 16,
              })}
            />
          </div>
        </div>
      );
    },
  },
  {
    id: LIST_TABLE_COLUMNS.LAST_DIALED,
    header: "Last dialed",
    accessorFn: ({ last_called }) => last_called,
    cell: ({ row }) => {
      const lastDialed = row.original?.last_called;

      return (
        <span>
          {lastDialed
            ? safeGetDate(
                lastDialed,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ
              )
            : "N/A"}
        </span>
      );
    },
  },
];

export const NEXT_TOUCH_TIME_COLUMN: ColumnDef<PipelineListContactI> = {
  id: LIST_TABLE_COLUMNS.NEXT_TOUCH_TIME,
  header: "Next touch time",
  accessorFn: ({ callback_time }) => callback_time,
  cell: ({ row }) => {
    const nextTouchTime = row.original?.callback_time;
    const isPast = isDatePast(nextTouchTime);
    const isToday = isDateToday(nextTouchTime);

    return (
      <div
        className={clsxMerge(
          "flex w-max items-center justify-center gap-1",
          "rounded border p-1.5 text-[#333]",
          {
            "border-[#CA3C27] text-[#CA3C27]": isPast,
            "border-[#349538] text-[#349538]": isToday && !isPast,
          }
        )}
      >
        <ClockIcon className="w-4" />
        <span className="typography-body-5-medium">
          {nextTouchTime
            ? isToday
              ? `Today, ${safeGetDate(nextTouchTime, "hh:mmA")}`
              : safeGetDate(nextTouchTime, "M/D, hh:mmA")
            : "N/A"}
        </span>
      </div>
    );
  },
};
