import { FC } from "react";

import { useStepsManager } from "shared/lib/hooks";
import SidebarV2 from "shared/ui/sidebarV2";

import { UploadLeadsSidebarProvider } from "./context";
import { INGESTION_TYPES } from "@/constants/ingestion";
import { UploadLeadsSidebarContentDownloadCSVExample } from "@/modules/pipeline/shared/upload-leads-sidebar/components/download-csv-example";
import { UploadLeadsSidebarContentUploadCSV } from "@/modules/pipeline/shared/upload-leads-sidebar/components/upload-csv";
import { UploadLeadsSidebarContentFieldsMap } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map";
import { UploadLeadsSidebarContentAddToList } from "@/modules/pipeline/shared/upload-leads-sidebar/components/add-to-list";
import { UploadLeadsSidebarContentAssignAE } from "@/modules/pipeline/shared/upload-leads-sidebar/components/assign-ae";
import { UploadLeadsSidebarContentStates } from "@/modules/pipeline/shared/upload-leads-sidebar/components/states";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import { useGlobalContext } from "@/hooks/use-global-context";

export interface UploadLeadsSidebarContentChildI {
  handleNextStep?: () => void;
  handlePrevStep?: () => void;
  onClose?: () => void;
}

interface UploadLeadsSidebarPropsI {
  isOpen: boolean;
  onClose: () => void;
}

const STEPS = {
  EXAMPLE_CSV: 0,
  UPLOAD_CSV: 1,
  ADD_TO_LIST: 2,
  FIELD_MAPPING: 3,
  ASSIGN_AE: 4,
  UPLOAD_STATUES: 5,
};

export const UploadLeadsSidebar: FC<UploadLeadsSidebarPropsI> = ({
  isOpen,
  onClose = () => {},
}) => {
  const { activeStep, decrementStep, incrementStep, setActiveStep } =
    useStepsManager({
      totalStepCount: Object.values(STEPS).length,
    });
  const { isFinishedSetup } = useOnboardingStatus();
  const { reloadUser } = useGlobalContext();

  const handleCloseSidebar = () => {
    setTimeout(() => {
      setActiveStep(0);

      // Need to reload user to update with latest onboarding status
      if (!isFinishedSetup) {
        reloadUser?.();
      }
    }, 500);

    onClose();
  };

  return (
    <UploadLeadsSidebarProvider>
      <SidebarV2
        isOpen={isOpen}
        onClose={handleCloseSidebar}
        activeStepIndex={activeStep}
        backgroundBlurClassName={
          [STEPS.EXAMPLE_CSV, STEPS.UPLOAD_CSV].includes(activeStep)
            ? "bg-white"
            : undefined
        }
      >
        <>
          {activeStep === STEPS.EXAMPLE_CSV && (
            <UploadLeadsSidebarContentDownloadCSVExample
              handleNextStep={incrementStep}
              onClose={handleCloseSidebar}
            />
          )}

          {activeStep === STEPS.UPLOAD_CSV && (
            <UploadLeadsSidebarContentUploadCSV
              handleNextStep={incrementStep}
              handlePrevStep={decrementStep}
              onClose={handleCloseSidebar}
            />
          )}

          {activeStep === STEPS.ADD_TO_LIST && (
            <UploadLeadsSidebarContentAddToList
              handlePrevStep={decrementStep}
              handleNextStep={incrementStep}
              onClose={handleCloseSidebar}
            />
          )}

          {activeStep === STEPS.FIELD_MAPPING && (
            <UploadLeadsSidebarContentFieldsMap
              ingestionType={INGESTION_TYPES.CSV}
              handleNextStep={incrementStep}
              handlePrevStep={decrementStep}
              onClose={handleCloseSidebar}
            />
          )}

          {activeStep === STEPS.ASSIGN_AE && (
            <UploadLeadsSidebarContentAssignAE
              handleNextStep={incrementStep}
              onClose={handleCloseSidebar}
            />
          )}

          {activeStep === STEPS.UPLOAD_STATUES && (
            <UploadLeadsSidebarContentStates onClose={handleCloseSidebar} />
          )}
        </>
      </SidebarV2>
    </UploadLeadsSidebarProvider>
  );
};
