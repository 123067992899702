import { useMemo } from "react";
import { useRouter } from "next/router";

import {
  FeatureIntroTooltipButtonI,
  FeatureIntroTooltipGuideStepsI,
} from "shared/ui/feature-intro/tooltip-guide";
import { NURTURE_LEADS_BUTTON_ID } from "shared/ui/lists/list-subsection-switch/constants";

import {
  LISTS_ACCOUNT_DETAILS_SUMMARY_SECTION_ID,
  LISTS_ADD_LEAD_MENU_ID,
  LISTS_START_CALLING_BUTTON_ID,
  LIST_SELECTOR_MENU_ID,
  LIST_SELECTOR_MENU_TRIGGER_ID,
} from "@/constants/element-ids";
import { WIDGETS, widgets } from "@/widgets/index";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useListTourContext } from "@/modules/tours/list/context";

const closeTourWidget = () => {
  widgets.close({ id: WIDGETS.MAXED_DIALER_TOUR });
};

export const useListTourSteps = (
  exitTour: () => void
): FeatureIntroTooltipGuideStepsI => {
  const { replace } = useRouter();
  const { setTourCompleted } = useListTourContext();

  const exitTourButton: FeatureIntroTooltipButtonI = {
    text: "Exit tour",
    actionHandler: (onClose) => {
      onClose();
      exitTour();
    },
  };

  return useMemo(
    () => ({
      [LISTS_ADD_LEAD_MENU_ID]: {
        title: "Add leads manually or via csv upload",
        description:
          "Your uploaded leads will only be visible and available to be called by you.",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
          },
          secondary: exitTourButton,
        },
      },
      [LISTS_START_CALLING_BUTTON_ID]: {
        tooltipContentClassName: "w-[320px]",
        title:
          "Select a lead to call directly or choose ‘Start calling’ to trigger the auto-dialer",
        description:
          "Click on a prospect to open their account details or let the auto-dialer work through your call list",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: () => {
              widgets.trigger({
                id: WIDGETS.MAXED_DIALER_TOUR,
                state: {
                  callStatus: DIALER_CALL_STATUS.DURING,
                },
              });
            },
          },
          secondary: exitTourButton,
        },
      },
      [LISTS_ACCOUNT_DETAILS_SUMMARY_SECTION_ID]: {
        tooltipContentClassName: "z-50",
        shouldOutlineReference: false,
        delayMs: 200,
        title: "Deep research about your accounts",
        description:
          "Our AI agents work with you to fill this information out with internet research, calls, emails and your notes.",
        placement: "right",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: () => {
              closeTourWidget();
            },
          },
          secondary: {
            ...exitTourButton,
            actionHandler: (onClose) => {
              closeTourWidget();
              exitTourButton.actionHandler?.(onClose);
            },
          },
        },
      },
      [NURTURE_LEADS_BUTTON_ID]: {
        tooltipContentClassName: "w-[310px]",
        shouldOutlineReference: false,
        title: "Nurture leads",
        description: (
          <>
            <p>
              If you had a conversation or an email reply with a lead, they'll
              be added to your nurture list otherwise they are cold leads. We
              track all of your calling and email activity for easy engagement.
            </p>
            <p className="mt-3">
              Learn more about nurture leads and lists{" "}
              <a
                className="link"
                href="https://help.glenx.ai/articles/3998679221-how-do-manual-and-ai-dispositions-work-and-how-do-leads-move-in-and-out-of-lists"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </>
        ),
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: () => {
              document.getElementById(LIST_SELECTOR_MENU_TRIGGER_ID)?.click();
            },
          },
          secondary: exitTourButton,
        },
      },
      [LIST_SELECTOR_MENU_ID]: {
        tooltipContentClassName: "w-[310px]",
        shouldOutlineReference: false,
        delayMs: 200,
        title: "Keep your pipeline organized with AI help",
        description: (
          <>
            <p>
              We manage some smart lists for you while you have full control
              over your custom lists.
            </p>
            <p className="mt-3">
              Learn more about nurture leads and lists{" "}
              <a
                className="link"
                href="https://help.glenx.ai/articles/3998679221-how-do-manual-and-ai-dispositions-work-and-how-do-leads-move-in-and-out-of-lists"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </>
        ),
        placement: "right",
        buttons: {
          primary: {
            text: "Got it",
            className: "w-[125px]",
            actionHandler: () => {
              closeTourWidget();
              exitTour();
              setTourCompleted(true);
              // Close the list selector menu
              document.getElementById(LIST_SELECTOR_MENU_TRIGGER_ID)?.click();
            },
          },
        },
      },
    }),
    [exitTourButton, replace]
  );
};
