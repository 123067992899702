export const COLD_LIST_TOOLTIP = {
  title: "Cold leads",
  content:
    "Cold leads are leads that you have not made a connect with (email response or conversation). The ratio is (# of leads available to call, leads that are not on cooldown) / (total leads)",
};

export const NURTURE_LIST_TOOLTIP = {
  title: "Nurture leads",
  content:
    "Nurture leads are leads with follow-ups. The ratio is (# of follow-ups available to call) / (total leads with follow-ups)",
};

export const COLD_LEADS_BUTTON_ID = "cold_leads_button";
export const NURTURE_LEADS_BUTTON_ID = "nurture_leads_button_id";
