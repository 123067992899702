import { FC, useState } from "react";
import toast from "react-hot-toast";

import { clsxMerge } from "shared/lib/helpers";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";

import { useUploadLeadsSidebarContext } from "@/modules/pipeline/shared/upload-leads-sidebar/context";
import { glencocoClientAPI } from "@/api/glencoco";
import { useFetchUploadLeadsMeetingTakers } from "@/modules/pipeline/shared/upload-leads-sidebar/components/assign-ae/queries/queries";
import { UploadLeadsSidebarContentChildI } from "@/modules/pipeline/shared/upload-leads-sidebar";
import { MeetingTakerDropdown } from "@/modules/pipeline/account-details/shared/meeting-taker-dropdown";

type UploadLeadsSidebarContentAssignAEPropsI = UploadLeadsSidebarContentChildI;

export const UploadLeadsSidebarContentAssignAE: FC<
  UploadLeadsSidebarContentAssignAEPropsI
> = ({ handleNextStep, onClose }) => {
  const { ingestionRequest, selectedListId, selectedCampaignId, ingestionId } =
    useUploadLeadsSidebarContext();
  const [isSubmittingIngestion, setIsSubmittingIngestion] = useState(false);
  const [selectedMeetingTakerId, setSelectedMeetingTakerId] = useState<
    string | null
  >();

  const meetingTakersApi = useFetchUploadLeadsMeetingTakers();

  const handleSaveIngestion = async () => {
    if (!selectedCampaignId || !ingestionId || !ingestionRequest) {
      return;
    }

    setIsSubmittingIngestion(true);

    const API = glencocoClientAPI();

    const submitIngestionResponse = await API.ingestions
      .submitIngestionFields(selectedCampaignId, ingestionId, {
        ...ingestionRequest,
        meeting_taker_id:
          selectedMeetingTakerId === null ? undefined : selectedMeetingTakerId,
        list_id: selectedListId,
      })
      .catch((e) => e);

    if (submitIngestionResponse.status === 200) {
      handleNextStep?.();
    } else {
      toast.error(
        "Failed to submit mapping due to internal server error. Please contact Glencoco team."
      );

      setIsSubmittingIngestion(false);
    }
  };

  return (
    <SidebarSlide
      title="Assign meeting taker"
      headerClassName="pb-0"
      contentClassName="pt-0"
      size={SIDEBAR_SIZE.MD}
      onClose={onClose}
    >
      <article>
        <section className="mt-4">
          <DataStatesWrapper api={meetingTakersApi} viewName="meeting takers">
            <MeetingTakerDropdown
              hasNoMeetingTakerOption
              onChange={({ id }) => setSelectedMeetingTakerId(id)}
            />
          </DataStatesWrapper>
        </section>

        <section
          className={clsxMerge(
            "z-100 fixed bottom-0 left-0 flex w-full justify-end gap-3",
            "py-4 pr-[22px]"
          )}
        >
          <button className="btn-nofill border-none" onClick={onClose}>
            Cancel
          </button>

          <button
            className={clsxMerge("btn-ae-default w-[197px]", {
              disabled:
                isSubmittingIngestion || selectedMeetingTakerId === undefined,
              loading: isSubmittingIngestion,
            })}
            onClick={handleSaveIngestion}
          >
            Next
          </button>
        </section>
      </article>
    </SidebarSlide>
  );
};
