import { FC, useEffect, useMemo, useState } from "react";
import { Check } from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { Button } from "shared/ui/shadcn/button";
import { Confetti } from "shared/ui/confetti";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import { useLocalStorage } from "@/context/local-storage";
import { OnboardingSetupModalHeader } from "@/modals/onboarding-setup-modal/header";
import { LISTS_ADD_LEADS_MENU_IMPORT_LEADS_BUTTON_ID } from "@/constants/element-ids";
import { EMAIL_SETUP_MODAL_ID } from "@/modals/email-setup-modal";

export const ONBOARDING_SETUP_MODAL_ID = "onboarding-setup-modal";

interface OnboardingSetupStepI {
  title?: string;
  description?: string;
  action?: () => void;
  isCompleted?: boolean;
}

interface OnboardingSetupModalPropsI {}

const OnboardingSetupModalContent: FC<
  OnboardingSetupModalPropsI & ModalChildrenFnPropsI
> = ({ handleClose }) => {
  const localStorage = useLocalStorage();
  const { flags, isFinishedSetup, isSingleUserLicense } = useOnboardingStatus();

  const [isConfettiVisible, setIsConfettiVisible] = useState(false);

  const { isEmailDomainSet, isUploadedLeadList } = flags;

  useEffect(() => {
    if (isFinishedSetup && !localStorage.onboardingSetupModalCompletedDate) {
      localStorage.onboardingSetupModalCompletedDate = new Date().toISOString();
      setIsConfettiVisible(true);
    }
  }, [isFinishedSetup]);

  const ONBOARDING_STEPS = useMemo(() => {
    const steps: OnboardingSetupStepI[] = [
      {
        title: "Finish setting up your custom email",
        description:
          "Leverage our AI agents and AI-assisted email copy generator to send emails to leads and nurture them",
        action: () => {
          handleClose();
          modalHelpers.open(EMAIL_SETUP_MODAL_ID);
        },
        isCompleted: isEmailDomainSet,
      },
      {
        title: "Import your first lead list",
        description:
          "Our smart dialer dials multiple prospects at once to increase your opportunities to connect",
        action: () => {
          handleClose();
          document
            .getElementById(LISTS_ADD_LEADS_MENU_IMPORT_LEADS_BUTTON_ID)
            ?.click();
        },
        isCompleted: isUploadedLeadList,
      },
    ];

    if (isSingleUserLicense) {
      steps.push({
        title: "",
        description: "Make your first 50 dials in the next 7 days",
        isCompleted: false,
      });
    }

    return steps;
  }, [isSingleUserLicense, isEmailDomainSet, isUploadedLeadList]);

  return (
    <div>
      <OnboardingSetupModalHeader />

      <div className="rounded-md border">
        {ONBOARDING_STEPS.map(
          ({ title, description, action, isCompleted }, index) => (
            <div
              key={index}
              className={clsxMerge(
                "grid grid-cols-[34px_1fr_66px] items-center gap-2",
                "px-3 py-2",
                { "border-b": index !== ONBOARDING_STEPS.length - 1 }
              )}
            >
              <Check
                className={isCompleted ? "text-[green]" : "text-black/20"}
              />

              <div className="mr-2">
                <h5 className="text-black typography-body-4-medium">{title}</h5>
                <p className="text-[#333] typography-body-4">{description}</p>
              </div>

              {action && (
                <Button
                  variant="default"
                  disabled={isCompleted}
                  onClick={action}
                >
                  {isCompleted ? "Done" : "Start"}
                </Button>
              )}
            </div>
          )
        )}
      </div>

      {isSingleUserLicense && (
        <div className="mt-3 text-[#333] typography-body-5">
          *Once the tasks have been achieved, the 2 extra weeks will be added to
          your trail period.
        </div>
      )}

      <div className="mt-6 flex gap-2">
        {isFinishedSetup ? (
          <Button variant="default" className="flex-1" onClick={handleClose}>
            Great! I'm ready to start dialing
          </Button>
        ) : (
          <Button variant="outline" className="flex-1" onClick={handleClose}>
            I'll setup my account later
          </Button>
        )}
      </div>

      {isConfettiVisible && <Confetti />}
    </div>
  );
};

export const OnboardingSetupModal: FC<OnboardingSetupModalPropsI> = (props) => (
  <Modal
    id={ONBOARDING_SETUP_MODAL_ID}
    modalBoxClassName="rounded-md px-8 py-9"
  >
    {(modalProps) => {
      return <OnboardingSetupModalContent {...props} {...modalProps} />;
    }}
  </Modal>
);
