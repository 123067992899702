import { FC } from "react";

import SearchField from "shared/ui/ae-user-input/search-field";
import { clsxMerge } from "shared/lib/helpers";

interface PipelineSearchPropsI {
  className?: string;
  inputClassName?: string;
  onSearch: (searchValue: string) => void;
}

const PipelineSearch: FC<PipelineSearchPropsI> = ({ onSearch, className }) => (
  <SearchField
    inputId="pipelinesSearch"
    isClearable
    className={clsxMerge("h-10 w-[400px] bg-white", className)}
    iconClassName="ml-2 w-6 h-6"
    inputClassName="ae-typography-detail1 font-normal pl-2"
    isLoadingIndicatorVisible={false}
    debounceTime={100}
    isLoadingData={false}
    placeholder="Search by contact name, title, or account name"
    onSearch={onSearch}
  />
);

export default PipelineSearch;
