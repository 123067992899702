import { FC } from "react";
import { Portal } from "react-portal";

import { ButtonMenu } from "shared/ui";

import { UploadLeadsSidebar } from "@/modules/pipeline/shared/upload-leads-sidebar";
import {
  ADD_NEW_LEAD_TO_LIST_MODAL,
  AddNewLeadToListModal,
} from "@/modals/lists/add-new-lead-to-list";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import {
  LISTS_ADD_LEAD_MENU_ID,
  LISTS_ADD_LEADS_MENU_IMPORT_LEADS_BUTTON_ID,
} from "@/constants/element-ids";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

interface AddOrImportLeadsButtonsPropsI {
  isUploadLeadsSidebarOpen: boolean;
  onOpenUploadLeadsSidebar: () => void;
  onCloseUploadLeadsSidebar: () => void;
  loadAccounts?: () => void;
}

/**
 * This component represents a dropdown with "New Lead" and "Import Leads"
 * buttons used in the header of the Leads pages (open pipeline and lists).
 *
 * @param isUploadLeadsSidebarOpen
 * @param onOpenUploadLeadsSidebar
 * @param onCloseUploadLeadsSidebar
 * @param loadAccounts
 * @constructor
 */
export const AddOrImportLeadsButtons: FC<AddOrImportLeadsButtonsPropsI> = ({
  isUploadLeadsSidebarOpen,
  onOpenUploadLeadsSidebar,
  onCloseUploadLeadsSidebar,
}) => {
  return (
    <div>
      <ButtonMenu
        id={LISTS_ADD_LEAD_MENU_ID}
        position="end"
        className="btn-ae-default w-[118px]"
        text="Add Lead"
        items={[
          <ButtonMenuItem
            text="New Lead"
            onClick={() => modalHelpers.trigger(ADD_NEW_LEAD_TO_LIST_MODAL)}
          />,
          <ButtonMenuItem
            id={LISTS_ADD_LEADS_MENU_IMPORT_LEADS_BUTTON_ID}
            text="Import Leads"
            onClick={onOpenUploadLeadsSidebar}
          />,
        ]}
      />

      {/*@ts-ignore*/}
      <Portal>
        <UploadLeadsSidebar
          isOpen={isUploadLeadsSidebarOpen}
          onClose={onCloseUploadLeadsSidebar}
        />

        <AddNewLeadToListModal />
      </Portal>
    </div>
  );
};
