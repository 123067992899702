import { useEffect } from "react";

import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import { InfoModal } from "@/components/modals/info-modal";
import { INFO_MODAL_DIALING_LIST_COMPLETE_ID } from "../constants";
import { ListTourModal } from "@/modals/lists/list-tour-modal";
import { ListTour } from "@/modules/tours/list";
import { useListTourContext } from "@/modules/tours/list/context";
import {
  ONBOARDING_SETUP_MODAL_ID,
  OnboardingSetupModal,
} from "@/modals/onboarding-setup-modal";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import { useLocalStorage } from "@/context/local-storage";

export const ListsModalsAndTours = () => {
  const { openTour } = useListTourContext();
  const { isFinishedSetup } = useOnboardingStatus();
  const localStorage = useLocalStorage();

  // When the user finishes setup, we auto-trigger the onboarding setup modal
  // to show a successful confetti animation
  useEffect(() => {
    if (isFinishedSetup && !localStorage.onboardingSetupModalCompletedDate) {
      modalHelpers.open(ONBOARDING_SETUP_MODAL_ID);
    }
  }, [isFinishedSetup]);

  return (
    <>
      <ListTour />

      <ListTourModal onEnterTour={openTour} />

      <OnboardingSetupModal />

      <InfoModal
        id={INFO_MODAL_DIALING_LIST_COMPLETE_ID}
        title="List dialing complete"
        description="All contacts have been called through today"
        confirmButtonText="Got it"
        showCloseButton={false}
      />
    </>
  );
};
