import { AxiosResponse } from "axios";
import {
  SetStateAction,
  useEffect,
  Dispatch,
  MutableRefObject,
  useRef,
  EventHandler,
  useMemo,
} from "react";
import wait from "wait";
import async from "async";
import throttle from "lodash/throttle";

import {
  GetListDetailsResponseI,
  GetListResponseI,
  GetListsRequestFilterParamsI,
} from "@/api/routes/list";
import { asyncGet } from "@/helpers/context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";

import { PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID } from "@/constants/element-ids";
import { selectorAvailableLeads, selectorSyncWithDialerList } from "./selector";
import { disableScroll, enableScroll } from "@/helpers/scroll";
import { isArrayEqual } from "shared/lib/helpers/utils";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import { INFO_MODAL_DIALING_LIST_COMPLETE_ID } from "@/modules/pipeline/lists/list/constants";

import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { dispatchCustomEvent } from "@/helpers/events";
import { OnChangeListFiltersI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";
import { LIST_SUBSECTIONS_MAP } from "shared/lib/constants/lists";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";
import { PipelineListContactI } from "shared/lib/interfaces/lists";

/**
 * 
[DIALER LIST][LEADS] - check if leads are availabile to call {data: {…}}
 [DIALER LIST][LEADS] - get next page leads {data: {…}}
 */

/**
 * Custom hook to enhance the base list functionality by integrating
 * dialer capabilities, allowing the ability to manage and dial leads directly from the list.
 * It handles synchronization of leads with the dialer system, refetches data when necessary,
 * and manages the lead queue for dialing operations.
 *
 * This hook ties into a global dialer context and listens for dialer state changes,
 * such as turning the dialer on or off, and dynamically updates the lead list
 * by adding available leads to the queue, synchronizing the table data with dialing status,
 * and fetching more leads when the queue is depleted.
 */
export const useDialerListQueueTableDataExtension = ({
  apiRef,
  listDetails,
  filters,
  onChangeFilters,
  setTableContacts,
}: {
  apiRef: MutableRefObject<
    | UseInfiniteQueryResult<
        InfiniteData<AxiosResponse<GetListResponseI, any>, unknown>,
        Error
      >
    | undefined
  >;
  filters?: GetListsRequestFilterParamsI;
  onChangeFilters: OnChangeListFiltersI;
  listDetails?: GetListDetailsResponseI;
  setTableContacts: Dispatch<SetStateAction<PipelineListContactI[]>>;
}) => {
  const {
    listId,
    isIdle,
    isEnabled: isGlobalDialer,
    isSessionClosing,
    addToLeadsQueue,
    setIsEnabled,
    setIsSessionClosing,
    leadsDialing,
    leadsProcessed,
    setLeadsMap,
    syncLeadsWithUpdatedList,
    endSession,
  } = useDialerGlobalContext();

  const listDetailsRef = useRef(listDetails);
  listDetailsRef.current = listDetails;

  const getNextPageLeads = async () => {
    dd.log(`${LOG_CATEGORIES.DIALER_LIST}[LEADS] - get next page attempt`, {
      data: {
        hasNextPage: apiRef?.current?.hasNextPage,
      },
    });

    if (!apiRef?.current?.hasNextPage) return undefined;

    const resp = await apiRef?.current?.fetchNextPage();

    const lastPageIndex = (resp?.data?.pages?.length || 1) - 1;
    const nextPageLeads =
      resp?.data?.pages?.[lastPageIndex]?.data?.contacts || [];

    /**
     * Workaround
     * We need to figure out why reference in apiRef.current.hasNextPage is always true
     */
    apiRef.current.hasNextPage =
      !!resp?.data?.pages?.[lastPageIndex]?.data?.next_token;

    dd.log(`${LOG_CATEGORIES.DIALER_LIST}[LEADS] - get page lastPageIndex`, {
      data: {
        response: resp?.data,
        hasNextPage: apiRef?.current?.hasNextPage,
      },
    });

    return nextPageLeads;
  };

  const getMoreLeads = async () => {
    let availableLeads: PipelineListContactI[] = [];

    /**
     * Max amount of pages per search for leads
     * To protect against infinite loops
     */
    const safeguardMaxTries = 200;
    let safeguardCounterTries = 0;

    /* Continue requesting new pages until we get some leads.*/
    try {
      /**
       * Like ['doWhilst']{@link module:ControlFlow.doWhilst}, except the test is inverted. Note the
       * argument ordering differs from until.
       *
       * @name doUntil
       * @static
       * @memberOf module:ControlFlow
       * @method
       * @see [async.doWhilst]{@link module:ControlFlow.doWhilst}
       * @category Control Flow
       * @param {AsyncFunction} iteratee - An async function which is called each time
       * test fails. Invoked with (callback).
       * @param {AsyncFunction} test - asynchronous truth test to perform after each
       * execution of iteratee. Invoked with (...args, callback), where ...args are the
       * non-error args from the previous callback of iteratee
       * @param {Function} [callback] - A callback which is called after the test
       * function has passed and repeated execution of iteratee has stopped. callback
       * will be passed an error and any arguments passed to the final iteratee's
       * callback. Invoked with (err, [results]);
       * @returns {Promise} a promise, if no callback is passed
       */
      await async.doUntil(
        /**
         * Incorrect original typescript declaration in d.ts
         *
         */
        // @ts-ignore:next-line
        async (callback) => {
          safeguardCounterTries++;
          const leads = await getNextPageLeads();

          const isSafeguardTiesMaxedOut =
            safeguardMaxTries < safeguardCounterTries;

          if (!leads || isSafeguardTiesMaxedOut) {
            if (isSafeguardTiesMaxedOut)
              dd.log(
                `${ERROR_CATEGORIES.DIALER_LIST}[LEADS] - safeguard trigger - request more leads infinite loop attempt`
              );

            callback(new Error("no more leads"));
            return;
          }

          availableLeads = selectorAvailableLeads(leads);

          callback(null, availableLeads);
        },
        /**
         * Incorrect original typescript declaration
         *
         * @param availableLeads
         * @param callback
         */
        // @ts-ignore:next-line
        async (availableLeads, callback) => {
          const isDialerEnabled = await asyncGet(setIsEnabled);
          const isSessionClosing = await asyncGet(setIsSessionClosing);

          dd.log(
            `${LOG_CATEGORIES.DIALER_LIST}[LEADS] - check if leads are availabile to call`,
            {
              data: {
                availableLeads: availableLeads?.length,
                isDialerEnabled,
                isSessionClosing,
              },
            }
          );

          callback(
            null,
            availableLeads?.length > 0 || !isDialerEnabled || isSessionClosing
          );
        }
      );
    } catch (e) {
      console.log(`No leads to Call`);
    }

    return availableLeads;
  };

  /**
   * On Dialer turn on/off add available leads to the dialer queue
   * or refetch current list
   */
  useEffect(() => {
    (async () => {
      if (!isGlobalDialer) {
        apiRef.current?.refetch();
        return;
      }

      const leads = await asyncGet(setTableContacts);

      let availableLeads = selectorAvailableLeads(leads);

      if (availableLeads?.length) {
        addToLeadsQueue?.(availableLeads);
        return;
      }

      availableLeads = await getMoreLeads();
      addToLeadsQueue?.(availableLeads);
    })();
  }, [isGlobalDialer]);

  /**
   * Scroll to currently dialing leads
   */
  const timeout = useRef<number>();
  useEffect(() => {
    if (isGlobalDialer) {
      clearTimeout(timeout.current);

      const scrollToTheFirstDialingLead = async () => {
        const tableContacts = await asyncGet(setTableContacts);

        const indexOfTheFirstDialingLead = tableContacts
          .map((lead) => lead?.status)
          .indexOf(DIALER_LIST_DIALING_STATUS.DIALING);

        //** Calculate scroll height required to get to the first dialing lead */
        const MAX_ROW_HEIGHT = 56;
        const INITIAL_TOP_OFFSET = 200;
        const EXPANDED_TOP_OFFSET = 520;
        const screenHeight = window.innerHeight;

        const isScroll =
          INITIAL_TOP_OFFSET + indexOfTheFirstDialingLead * MAX_ROW_HEIGHT >
          screenHeight - EXPANDED_TOP_OFFSET;

        if (isScroll) {
          disableScroll();
          window.document
            .querySelector(`#${PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID}`)
            ?.scrollTo({
              top:
                INITIAL_TOP_OFFSET +
                indexOfTheFirstDialingLead * MAX_ROW_HEIGHT -
                screenHeight / 4,
              behavior: "smooth",
            });
          setTimeout(() => {
            enableScroll();
          }, 1000);
        }
      };

      timeout.current = window.setTimeout(() => {
        scrollToTheFirstDialingLead();
      }, 1000);
    }
  }, [isGlobalDialer, leadsDialing]);

  /**
   * Sync table data with leads from the dialer
   * to show lead status accordingly
   */
  useEffect(() => {
    setTableContacts((oldLeads) => {
      const newLeads = selectorSyncWithDialerList({
        data: oldLeads,
        leadsDialing,
        leadsProcessed,
      });

      const isEqual = isArrayEqual(newLeads, oldLeads);

      return isEqual ? oldLeads : newLeads;
    });
  }, [leadsDialing, leadsProcessed]);

  /**
   * useEffect hook to listen for a custom event that indicates the leads queue is running low
   * and triggers a throttled function to fetch more leads and add them to the queue.
   *
   */
  useEffect(() => {
    /**
     * Throttled function to fetch more leads and add them to the leads queue when necessary.
     *
     * - Fetches the current queue of leads and filters out those already in the leads map.
     * - If there are more pages of leads available, fetches the next pages of leads.
     * - Filters available leads using `selectorAvailableLeads`.
     * - Adds available leads to the queue if any are found.
     */
    const addMoreLeadsThrottled = throttle(async () => {
      const isGlobalDialerEnabled = await asyncGet(setIsEnabled);
      if (!isGlobalDialerEnabled) return;

      const isSessionClosing = await asyncGet(setIsSessionClosing);
      if (isSessionClosing) return;

      dd.rum.log(`${LOG_CATEGORIES.DIALER_LIST}[LEADS] - requested more leads`);

      // Step 1: Retrieve the current leads map asynchronously.
      const queueLeadsMap = await asyncGet(setLeadsMap);
      // Step 2: Get all current leads from the API response, flattening paginated data and filtering out leads that are not available.
      const allCurrentAvailableLeads = selectorAvailableLeads(
        apiRef?.current?.data?.pages
          ?.flatMap((page) => page.data?.contacts)
          ?.filter(Boolean) || []
      );

      // Step 3: Filter out leads that are already in the queue.
      const additionalQueueLeads = allCurrentAvailableLeads.filter(
        (l) => !queueLeadsMap.get(l.list_membership_id)
      );

      if (additionalQueueLeads?.length > 0) {
        dd.rum.log(
          `${LOG_CATEGORIES.DIALER_LIST}[LEADS] - adding leads that are currently available ${additionalQueueLeads?.length}`,
          {
            data: {
              queueLeadsMap: Array.from(queueLeadsMap),
              allCurrentAvailableLeads,
              additionalQueueLeads,
            },
          }
        );
        addToLeadsQueue?.(additionalQueueLeads);
        return;
      }

      // Step 4: If more pages of leads are available, fetch the next page.
      if (apiRef?.current?.hasNextPage) {
        const availableLeads = await getMoreLeads();

        if (!availableLeads?.length) {
          dd.rum.log(
            `${LOG_CATEGORIES.DIALER_LIST}[LEADS] - no more leads found`
          );

          return;
        }

        dd.rum.log(
          `${LOG_CATEGORIES.DIALER_LIST}[LEADS] - adding more leads ${availableLeads?.length}`
        );
        addToLeadsQueue?.(availableLeads);
      }
    }, 1000);

    window.document.addEventListener(
      CUSTOM_EVENTS.LIST_DIALING.LEADS_RUNNING_OUT,
      addMoreLeadsThrottled as EventHandler<any>
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.LIST_DIALING.LEADS_RUNNING_OUT,
        addMoreLeadsThrottled as EventHandler<any>
      );
    };
  }, []);

  const isDialerOff = useMemo(
    () => !isGlobalDialer || isSessionClosing,
    [isGlobalDialer, isSessionClosing]
  );

  const isMoreData = useMemo(
    () => apiRef?.current?.isLoading || apiRef?.current?.hasNextPage,
    [apiRef?.current?.isLoading, apiRef?.current?.hasNextPage]
  );

  const isLeadsAvailable = useMemo(() => {
    if (
      filters?.list_subsection === LIST_SUBSECTIONS_MAP.COLD_CALL &&
      (listDetails?.number_available_contacts_to_call_cold || 0) > 0
    ) {
      return true;
    }

    if (
      filters?.list_subsection === LIST_SUBSECTIONS_MAP.NURTURE &&
      (listDetails?.number_available_contacts_to_call_nurture || 0) > 0
    ) {
      return true;
    }

    return false;
  }, [
    listDetails?.number_available_contacts_to_call_nurture,
    listDetails?.number_available_contacts_to_call_cold,
    filters?.list_subsection,
  ]);

  /**
   * End session logic
   */
  useEffect(() => {
    if (isDialerOff || isMoreData || isLeadsAvailable) return;

    if (isIdle) {
      endSession();

      onChangeFilters((filters) => ({
        ...filters,
        filter_available_to_call: false,
      }));

      dispatchCustomEvent({ eventType: CUSTOM_EVENTS.LIST_DIALING.EXIT });
    }
  }, [isDialerOff, isMoreData, isLeadsAvailable, isIdle]);

  useEffect(() => {
    if (isIdle && isLeadsAvailable && !isMoreData) {
      dd.error(
        `${ERROR_CATEGORIES.DIALER_LIST} - available leads while in idle state`,
        {
          data: {
            listId,
            subsection: filters?.list_subsection,
            number_available_contacts_to_call_nurture:
              listDetails?.number_available_contacts_to_call_nurture,
            number_available_contacts_to_call_cold:
              listDetails?.number_available_contacts_to_call_cold,
          },
        }
      );
    }
  }, [isIdle, isLeadsAvailable, isMoreData]);

  /**
   * Listen to events that require filtering or adding more leads
   *
   * We already handling query update through mutation
   * Here we want to sync mutated list with currently dialing list
   */
  useEffect(() => {
    const handleAccountUpdate = () => {
      (async () => {
        await wait(500);
        const isEnabledGlobalDialer = await asyncGet(setIsEnabled);
        const isClosingGlobalDialer = await asyncGet(setIsSessionClosing);
        const tableContacts = await asyncGet(setTableContacts);

        if (isEnabledGlobalDialer && !isClosingGlobalDialer) {
          syncLeadsWithUpdatedList?.(selectorAvailableLeads(tableContacts));
        }
      })();
    };

    //TODO extend to handle reason
    const handleListDialingExit = () => {
      modalHelpers.open(INFO_MODAL_DIALING_LIST_COMPLETE_ID);
    };

    //TODO improve typescript
    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.RELOAD_COMPLETE,
      handleAccountUpdate as EventHandler<any>
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.LIST_DIALING.EXIT,
      handleListDialingExit as EventHandler<any>
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.RELOAD_COMPLETE,
        handleAccountUpdate as EventHandler<any>
      );
      window.document.removeEventListener(
        CUSTOM_EVENTS.LIST_DIALING.EXIT,
        handleListDialingExit as EventHandler<any>
      );
    };
  }, []);
};
