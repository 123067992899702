import FeatureIntroTooltipGuide from "shared/ui/feature-intro/tooltip-guide";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import { useLocalStorage } from "@/context/local-storage";
import { LIST_TOUR_MODAL_ID } from "@/modals/lists/list-tour-modal";
import { useListTourSteps } from "@/modules/tours/list/hooks";
import { useListTourContext } from "@/modules/tours/list/context";

export const ListTour = () => {
  const { isTourOpen, closeTour } = useListTourContext();
  const localStorage = useLocalStorage();

  useEffectOnce(() => {
    if (!localStorage.listTourModalVisitedDate) {
      modalHelpers.open(LIST_TOUR_MODAL_ID);
    }
  });

  const tourSteps = useListTourSteps(closeTour);

  return <FeatureIntroTooltipGuide isActive={isTourOpen} steps={tourSteps} />;
};
