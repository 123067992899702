/**
 * https://stackoverflow.com/a/4770179
 */
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36

import { checkIfClient } from "shared/lib/helpers";

const keys: { [key: number]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: Event): void {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: KeyboardEvent): boolean {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
  return true;
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  if (checkIfClient()) {
    window.addEventListener(
      "test",
      null as unknown as EventListenerOrEventListenerObject,
      Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  }
} catch (e) {
  console.log(e);
}

const getWheelEvent = () =>
  "onwheel" in window.document.createElement("div") ? "wheel" : "mousewheel";

const getWheelOpt = () => {
  const wheelOpt: AddEventListenerOptions | boolean = supportsPassive
    ? { passive: false }
    : false;

  return wheelOpt;
};

// call this to Disable
export function disableScroll(): void {
  const wheelEvent = getWheelEvent();
  const wheelOpt = getWheelOpt();

  window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll(): void {
  const wheelEvent = getWheelEvent();
  const wheelOpt = getWheelOpt();

  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener("touchmove", preventDefault, wheelOpt);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}
