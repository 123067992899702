import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import _map from "lodash/map";
import _find from "lodash/find";
import { useQueryClient } from "@tanstack/react-query";

import { BasicMultiselect } from "shared/ui/user-input/multiselect";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import {
  CONFIRM_ADD_TO_LIST_MODAL_ID,
  ConfirmAddToListModal,
} from "@/modals/lists/confirm-add-to-list";
import { useFetchLists } from "@/modules/pipeline/lists/list/queries/queries";

import { clsxMerge } from "shared/lib/helpers";
import {
  CONFIRM_REMOVE_CONTACTS_FROM_LIST,
  ConfirmRemoveContactsFromList,
} from "@/modals/lists/confirm-remove-contacts-from-list";
import {
  FolderMinusIcon,
  ListBulletIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import { Table } from "@tanstack/react-table";
import {
  CONFIRM_DISQUALIFY_CONTACTS_FROM_LIST,
  ConfirmDisqualifyContactsFromList,
} from "@/modals/lists/confirm-disqualify-contacts-from-list";
import { DialerButton } from "./dialer-button";
import { GetListsRequestFilterParamsI } from "@/api/routes/list";
import { CallSettingsDropdown } from "@/modules/pipeline/lists/list/workspace/table/actions/call-settings-dropdown";
import {
  useBulkAddContactsToList,
  useBulkDisqualifyContactsFromList,
  useBulkRemoveContactsFromList,
} from "@/modules/pipeline/lists/list/queries/mutations";
import { LISTS_QUERY_KEY } from "@/modules/pipeline/lists/list/queries/keys";
import { PipelineListContactI } from "shared/lib/interfaces/lists";

interface LeadListsWorkspaceTableActionsPropsI {
  className?: string;
  selectedContacts: PipelineListContactI[];
  activeListId: string;
  isExclusiveList?: boolean;
  isSearchMode?: boolean;
  isDialerActionsDisabled?: boolean;
  disableLeadRemoval?: boolean;
  onChangeFilters: Dispatch<
    SetStateAction<GetListsRequestFilterParamsI | undefined>
  >;
}

const mapSelectionToBulkAddRequest = (data: PipelineListContactI[]) =>
  data.map(({ contact_id, campaign_id }) => ({
    contact_id,
    campaign_id,
  }));

const LeadListsWorkspaceTableActionsRaw = (
  {
    className,
    selectedContacts,
    activeListId,
    isSearchMode,
    isExclusiveList = false,
    disableLeadRemoval = false,
    isDialerActionsDisabled = false,
    onChangeFilters,
  }: LeadListsWorkspaceTableActionsPropsI,
  tableRef: ForwardedRef<Table<PipelineListContactI>>
) => {
  const table = tableRef as MutableRefObject<Table<PipelineListContactI>>;
  const isBulkActionAvailable = selectedContacts.length > 0;

  const queryClient = useQueryClient();

  const [targetLists, setTargetLists] = useState<string[]>([]);

  const { mutateAsync: bulkAddToList } = useBulkAddContactsToList();
  const { mutateAsync: bulkRemoveFromList } =
    useBulkRemoveContactsFromList(activeListId);
  const { mutateAsync: bulkDisqualify } =
    useBulkDisqualifyContactsFromList(activeListId);

  const { data: lists } = useFetchLists();

  const targetListsOptions = useMemo(
    () =>
      lists?.data?.user_lists
        .filter(
          ({ is_default_list, id }) => !is_default_list && id !== activeListId
        )
        .map((list) => ({
          value: list.id,
          label: list?.name,
        })) || [],
    [lists?.data?.user_lists, activeListId]
  );

  const targetListNames = useMemo(() => {
    if (!lists?.data.user_lists) {
      return [];
    }

    return targetLists.map(
      (listId, idx) =>
        _find(lists.data.user_lists, ["id", listId])?.name || `List ${idx + 1}`
    );
  }, [targetLists, lists]);

  const handleConfirmAddToListModal = async (isRemoveLeads: boolean) => {
    await bulkAddToList({
      contacts: mapSelectionToBulkAddRequest(selectedContacts),
      list_ids: targetLists,
    }).catch(console.error);

    if (isRemoveLeads) {
      bulkRemoveFromList({
        membership_ids: _map(selectedContacts, "list_membership_id"),
      })
        .then(() => {
          table.current?.resetRowSelection();

          queryClient.invalidateQueries({
            queryKey: [LISTS_QUERY_KEY, activeListId],
          });
        })
        .catch(console.error);
    }
  };

  return (
    <>
      <div className={clsxMerge("flex gap-2 bg-white py-1.5", className)}>
        {!isSearchMode && (
          <DialerButton
            pipelineListId={activeListId}
            disabled={isDialerActionsDisabled}
            onChangeFilters={onChangeFilters}
          />
        )}

        <BasicMultiselect
          isDisabled={!isBulkActionAvailable}
          shouldConfirm
          canSelectAll={targetListsOptions.length > 1}
          onConfirm={() => modalHelpers.open(CONFIRM_ADD_TO_LIST_MODAL_ID)}
          value={targetLists}
          onChange={setTargetLists}
          options={targetListsOptions}
          selectWrapperClassName="btn-ae-outline btn-ae-s"
          menuClassName="max-h-[400px]"
          getLabel={() => (
            <div className="flex items-center gap-1.5">
              <ListBulletIcon className="h-4 w-4" />
              <span>Move to list</span>
            </div>
          )}
        />

        {!isSearchMode && (
          <>
            <button
              disabled={
                !isBulkActionAvailable || isSearchMode || disableLeadRemoval
              }
              className="btn-ae-outline btn-ae-s"
              onClick={() =>
                modalHelpers.open(CONFIRM_REMOVE_CONTACTS_FROM_LIST)
              }
            >
              <FolderMinusIcon className="mr-1.5 h-4 w-4" />
              Remove from list
            </button>

            {isExclusiveList && (
              <button
                disabled={!isBulkActionAvailable}
                className="btn-ae-outline btn-ae-s"
                onClick={() =>
                  modalHelpers.open(CONFIRM_DISQUALIFY_CONTACTS_FROM_LIST)
                }
              >
                <UserMinusIcon className="mr-1.5 h-4 w-4" />
                Disqualify
              </button>
            )}

            <CallSettingsDropdown />
          </>
        )}
      </div>

      <ConfirmAddToListModal
        leadsToAddCount={selectedContacts.length}
        targetListNames={targetListNames}
        onConfirm={handleConfirmAddToListModal}
      />

      <ConfirmRemoveContactsFromList
        onConfirm={() =>
          bulkRemoveFromList({
            membership_ids: _map(selectedContacts, "list_membership_id"),
          })
            .then(() => {
              table.current?.resetRowSelection();

              queryClient.invalidateQueries({
                queryKey: [LISTS_QUERY_KEY, activeListId],
              });
            })
            .catch(console.error)
        }
      />

      <ConfirmDisqualifyContactsFromList
        leadsToDisqualifyCount={selectedContacts.length}
        onConfirm={(reason) =>
          bulkDisqualify({
            membership_ids: _map(selectedContacts, "list_membership_id"),
            reason,
          })
            .then(() => table.current?.resetRowSelection())
            .catch(console.error)
        }
      />
    </>
  );
};

export const LeadListsWorkspaceTableActions = forwardRef(
  LeadListsWorkspaceTableActionsRaw
);
