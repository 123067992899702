import { FC, useCallback, useMemo } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import { LiteralTypeFromArray } from "shared/lib/interfaces/utils";
import { setListTableHeaderOffsetTop } from "../workspace/table/utils";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { SYSTEM_NURTURE_LIST_TYPES } from "shared/lib/constants/lists";
import { getSortedLists } from "shared/lib/helpers/lists";
import { ListSubsectionI } from "shared/lib/interfaces/lists";
import { ListSubsectionSwitch } from "shared/ui/lists/list-subsection-switch";

import { ListsSelector } from "@/components/modules/pipeline/lists/list/primary-header/selector";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { useListTourContext } from "@/modules/tours/list/context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { ListActions as Actions } from "@/modules/pipeline/lists/list/primary-header/actions";
import { ListsOnboardingActions } from "@/modules/pipeline/lists/list/primary-header/actions/onboarding-actions";

export const LeadListsPrimaryHeader: FC<PropsWithClassNameI> = ({
  className,
}) => {
  const { isTourOpen } = useListTourContext();

  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const {
    activeListId,
    activeList,
    listDetails,
    filters,
    listsAPI,
    onChangeFilters,
  } = useListsContext();

  const { data } = listsAPI;

  const sortedLists = useMemo(
    () => getSortedLists(data?.data?.user_lists),
    [data]
  );

  const isNurtureMode =
    activeList?.list_type &&
    !SYSTEM_NURTURE_LIST_TYPES.includes(
      activeList?.list_type as LiteralTypeFromArray<
        typeof SYSTEM_NURTURE_LIST_TYPES
      >
    );

  const isSwitchVisible = isNurtureMode || isTourOpen;

  const handleUpdateListSubsection = useCallback(
    (subsection: ListSubsectionI) => {
      onChangeFilters((filters) => ({
        ...filters,
        list_subsection: subsection,
      }));

      setListTableHeaderOffsetTop();
    },
    [onChangeFilters, activeListId]
  );

  return (
    <section
      className={clsxMerge(
        "flex flex-col px-6 pb-5 pl-3",
        "transition-opacity duration-300",
        {
          "pointer-events-none opacity-50": isGlobalDialer,
        },
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <DataStatesWrapper
            api={listsAPI}
            loading={
              <LoadingSpinner className="flex items-center px-4 text-black" />
            }
          >
            <ListsSelector items={sortedLists} />
          </DataStatesWrapper>

          <Actions />
        </div>

        <div className="flex items-center gap-3">
          {isSwitchVisible && (
            <ListSubsectionSwitch
              className="h-5"
              isDemoMode={isTourOpen}
              isDefaultList={activeList?.is_default_list}
              isDisabled={isGlobalDialer}
              value={filters?.list_subsection}
              onChange={handleUpdateListSubsection}
              coldLeadsTotal={listDetails?.number_cold_contacts}
              coldLeadsAvailable={
                listDetails?.number_available_contacts_to_call_cold
              }
              nurtureLeadsTotal={listDetails?.number_nurture_contacts}
              nurtureLeadsAvailable={
                listDetails?.number_available_contacts_to_call_nurture
              }
            />
          )}

          <ListsOnboardingActions className="h-5" />
        </div>
      </div>
    </section>
  );
};
