import {
  // isDateTodayOrPastIfAny,
  isCooldown,
} from "@/modules/pipeline/lists/list/workspace/table/utils";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";
import { PipelineListContactI } from "shared/lib/interfaces/lists";

export const selectorExcludeLeadsMap = (
  leadsDialing?: PipelineListContactI[],
  leadsProcessed?: PipelineListContactI[]
) =>
  [...(leadsDialing || []), ...(leadsProcessed || [])]?.reduce<
    Record<string, PipelineListContactI>
  >((map, lead) => {
    map[lead.list_membership_id as string] = lead;
    return map;
  }, {});

/**
 * This function keeps leads updated according to the latest dialing session state
 *
 *  - Sort leads by the following order:
 *    - Current Dialing
 *    - Current session's processed leads
 *    - Rest leads by the current active sort
 *
 *   - Show lead statuses only if leads are dialing or processed by dialer and that rest that are on cooldown
 *
 * @param config
 * @param config.data
 * @param config.leadsDialing
 * @param config.leadsProcessed
 * @returns
 */
export const selectorSyncWithDialerList = ({
  data = [],
  leadsDialing: _leadsDialing = [],
  leadsProcessed: _leadsProcessed = [],
}: {
  data?: PipelineListContactI[];
  leadsDialing?: PipelineListContactI[];
  leadsProcessed?: PipelineListContactI[];
}): PipelineListContactI[] => {
  /**
   * action details synchronisation with dialing list happens
   * after tableData is set so we need to make sure that
   * dialing and processed leads are in the data
   */
  const dataMap = Object.fromEntries(
    data?.map((l) => [l.list_membership_id as string, l])
  );

  const leadsDialing = _leadsDialing
    .filter((l) => dataMap[l.list_membership_id])
    .map((l) => ({
      ...dataMap[l.list_membership_id],
      status: l.status,
    }));

  const leadsProcessed = _leadsProcessed
    .filter((l) => dataMap[l.list_membership_id])
    .map((l) => ({
      ...dataMap[l.list_membership_id],
      status: l.status,
    }));

  const excludeLeadsMap = selectorExcludeLeadsMap(leadsDialing, leadsProcessed);

  const rest =
    data
      ?.filter((lead) => !excludeLeadsMap[lead.list_membership_id])
      ?.map((lead) => {
        const _lead =
          lead.status === DIALER_LIST_DIALING_STATUS.DIALING ||
          !isCooldown(lead?.cooldown_until)
            ? { ...lead, status: undefined }
            : lead;

        return _lead;
      }) || [];

  return [...leadsProcessed, ...leadsDialing, ...rest];
};
/**
 * Filters the available leads from the provided list of contacts.
 *
 * This function checks each lead in the provided list and includes them in the filtered results if they meet either of the following conditions:
 * 1. They are not in DO NOT CALL STATE (DNC)
 * 2. They do not have a cooldown period specified (`cooldown_until`).
 * 3. Their cooldown period has ended, and their callback time is today or has already passed, if any.
 *
 * If no list of contacts is provided, the function returns an empty list.
 *
 * @param {PipelineListContactI[]} [leads] - The optional list of contacts to filter.
 * @returns {PipelineListContactI[]} - The filtered list of available leads.
 */
//Old function
// export const selectorAvailableLeads = (leads?: PipelineListContactI[]) =>
//   leads?.filter((l) => {
//     if (!l?.cooldown_until) return true;

//     return (
//       !l.do_not_call &&
//       !isCooldown(l?.cooldown_until) &&
//       isDateTodayOrPastIfAny(l?.callback_time)
//     );
//   }) || [];

export const selectorAvailableLeads = (leads?: PipelineListContactI[]) =>
  leads?.filter((l) => l.is_callable) || [];
