import { useMemo } from "react";

import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import {
  useFetchListDetails,
  useSearchContacts,
} from "@/modules/pipeline/lists/list/queries/queries";

export const useListWorkspace = ({
  listId,
  searchTerm,
}: {
  listId: string;
  searchTerm?: string;
}) => {
  const { isEnabled } = useDialerGlobalContext();

  const searchContactsAPI = useSearchContacts(searchTerm);

  const isSearchMode = useMemo(
    () =>
      !!searchTerm &&
      (searchContactsAPI.isSuccess || searchContactsAPI.isFetching),
    [searchTerm, searchContactsAPI]
  );

  const {
    data: response,
    isPending,
    isError: isFetchListDetailsError,
  } = useFetchListDetails(listId as string, {
    isSearchMode,
    isPolling: isEnabled,
  });

  return useMemo(
    () => ({
      isSearchMode,
      searchContactsAPI,
      response,
      isPending,
      isFetchListDetailsError,
    }),
    [
      isSearchMode,
      searchContactsAPI,
      response,
      isPending,
      isFetchListDetailsError,
    ]
  );
};
