import { LeadLists } from "@/modules/pipeline/lists/list";
import { LeadsPageHeader } from "@/modules/pipeline/shared/leads-page-header";
import { ListsContextProvider } from "./context";

export const ListsModule = () => {
  return (
    <ListsContextProvider>
      <LeadsPageHeader className="sticky left-0 z-20 mb-4 w-full max-w-full px-6" />

      <LeadLists className="sticky left-0 mt-4 overflow-auto" />
    </ListsContextProvider>
  );
};
