import { useEffect, useMemo, useRef } from "react";
import toast from "react-hot-toast";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { useDebouncedValue } from "shared/lib/hooks/use-debounced-value";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

import { useApiClient } from "@/context/api-client";
import { selectorNormalizedFilters } from "../selectors";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { FetchListContactsParamsI } from "@/modules/pipeline/lists/list/queries/mutations";
import {
  getListContactsQueryKey,
  getListDetailsQueryKey,
  LISTS_QUERY_KEY,
  SEARCH_CONTACTS_QUERY_KEY,
} from "@/modules/pipeline/lists/list/queries/keys";

// Get all lists
export const useFetchLists = () => {
  const api = useApiClient();

  const listsApi = useQuery({
    queryKey: [LISTS_QUERY_KEY],
    queryFn: api.getLists,

    /**
     * Caching lists for 10 seconds. If any other mutation changes them, revalidation
     * is triggered and request will be sent disregarding the staleTime.
     */
    staleTime: TIME_INTERVALS_MS.ONE_SECOND * 10,
  });

  useEffect(() => {
    if (listsApi.isError) {
      toast.error("Failed to load your lists, please try to reload the page.");
    }
  }, [listsApi.isError]);

  return listsApi;
};

export type FetchListsApiI = ReturnType<typeof useFetchLists>;

export const useFetchListDetails = (
  listId: string,
  config: { isSearchMode?: boolean; isPolling?: boolean }
) => {
  const api = useApiClient();

  const { isSearchMode = false, isPolling = false } = config;

  const isPollingRef = useRef(isPolling);
  isPollingRef.current = isPolling;

  const listDetailsApi = useQuery({
    enabled: !!listId?.trim() && !isSearchMode,
    queryKey: getListDetailsQueryKey(listId),
    queryFn: () => api.getListDetails(listId),

    refetchInterval: () =>
      isPollingRef.current ? TIME_INTERVALS_MS.ONE_SECOND * 10 : false,
  });

  useEffect(() => {
    if (listDetailsApi.isError) {
      toast.error("Failed to load list details.");
    }
  }, [listDetailsApi.isError]);

  return listDetailsApi;
};

// Contacts queries and mutations
export const useFetchListContacts = ({
  listId,
  sortParams,
  filters,
  isNurtureList,
  isSystemList,
}: FetchListContactsParamsI) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const api = useApiClient();

  const normalizedFilters = useMemo(
    () => selectorNormalizedFilters(filters, isNurtureList, isSystemList),
    [filters, isNurtureList, isSystemList]
  );

  const isQueryEnabled = !!listId?.trim() && !!sortParams && !!filters;

  const listContactsApi = useInfiniteQuery({
    enabled: isQueryEnabled,
    queryKey: getListContactsQueryKey(listId, {
      sortParams: sortParams || {},
      filters: normalizedFilters,
    }),
    queryFn: ({ pageParam }) =>
      api.getListContacts(listId, {
        next_token: pageParam,
        ...(sortParams || {}),
        ...(normalizedFilters || {}),
      }),
    initialPageParam: undefined as string | null | undefined,
    refetchOnWindowFocus: !isGlobalDialer, // default: true

    getNextPageParam: (lastPage) => lastPage.data?.next_token || undefined,
  });

  useEffect(() => {
    if (listContactsApi.isError) {
      toast.error(
        "Failed to load portion of contacts. Please try to reload the page."
      );
    }
  }, [listContactsApi.isError]);

  return {
    ...listContactsApi,
    refetch: () => {
      if (isQueryEnabled) {
        /**
         * As query.refetch() DOESN'T respect enabled flag on query AND it's used
         * inside use-dialer-list-queue-table-data-extension.ts, in a state
         * right after list rendering, it's causing an extra unneeded API call.
         *
         * Modifying the refetch() to check if the query is enabled before calling
         * it.
         *
         * See more: https://github.com/TanStack/query/issues/1196#issuecomment-2118489049
         */
        return listContactsApi.refetch();
      }

      return Promise.resolve({}) as ReturnType<
        (typeof listContactsApi)["refetch"]
      >;
    },
  };
};

// Global search
export const useSearchContacts = (searchTerm?: string) => {
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 400);
  const api = useApiClient();

  return useInfiniteQuery({
    enabled: !!debouncedSearchTerm?.trim(),
    queryKey: [SEARCH_CONTACTS_QUERY_KEY, debouncedSearchTerm],
    queryFn: ({ pageParam }) =>
      api.searchContacts({
        next_token: pageParam,
        search_term: debouncedSearchTerm || "",
      }),
    initialPageParam: undefined as string | null | undefined,
    getNextPageParam: (lastPage) => lastPage.data?.next_token || undefined,
  });
};

export type SearchContactsApiI = ReturnType<typeof useSearchContacts>;
